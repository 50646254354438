.user_image_info {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin-top: 5px;
    width: 42px;
    height: 42px;

    border-radius: 50%;

    .user_img {
        width: 42px;
        height: 42px;
        margin: 0 auto;
        border-radius: 50%;

        object-fit: cover;
        &.img-border {
            border: 2px solid var(--mainColor1);
        }
    }
    .user-vip-img {
        position: absolute;
    }
    .vip_frame {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    .level_wrapper {
        position: absolute;
        width: 27px;
        height: 27px;
        right: -17px;
        bottom: -5px;
        background: var(--mainColor17);
        border: 2px solid var(--mainColor18);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: var(--mainColor18);
            font-weight: bold;
            font-size: 12px;
            padding: 5px;
        }
    }
}
.user_image_1,
.user_image_2,
.user_image_3,
.user_image_4 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 90px;
    height: 90px;
    border: 2px solid var(--mainColor1);
    border-radius: 50%;
    .user_image {
        width: 75px;
        height: 80px;

        img {
            width: 100%;
            border-radius: 50%;
        }
        .level_wrapper {
            position: absolute;
            width: 25px;
            height: 25px;
            right: -12px;
            bottom: 0px;
            background: var(--gColor);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                color: var(--fColor);
                font-weight: bold;
                font-size: 12px;
            }
        }
    }
    .user_image_1 {
        position: absolute;
        bottom: 0;
    }
}
.user_image_1 {
    left: 160px;
    bottom: 77px;
}
.user_image_2 {
    right: 54px;
    top: calc(50% - 45px);
}
.user_image_3 {
    top: 22px;
    left: calc(50% - 45px);
}
.user_image_4 {
    left: 54px;
    top: calc(50% - 45px);
}
