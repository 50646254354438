.first_start_1,
.first_start_2,
.first_start_3,
.first_start_4 {
    width: 33px;
    height: 33px;
    border: 1px solid var(--mainColor4);
    border-radius: 50%;
    position: absolute;
    span {
        color: var(--mainColor4);
        font-size: 12px;
        display: block;
        line-height: 32px;
        text-align: center;
        font-family: 'Roboto';
        font-weight: 700;
    }
}
.first_start_1 {
    left: -40px;
    bottom: 34px;
}
.first_start_2 {
    left: -45px;
    bottom: 0px;
}
.first_start_3 {
    left: -36px;
    bottom: -7px;
}
.first_start_4 {
    right: -46px;
    bottom: 0px;
}
