.tour-info-block {
    position: absolute;
    left: 440px;
    top: 105px;
    height: 510px;
    width: 560px;

    .tour-info-text {
        margin-bottom: 38px;
        margin-top: 10px;
        p {
            color: var(--mainColor2);
            font-size: 21px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 1px;
            position: relative;
            top: -5px;
        }
        span {
            color: var(--mainColor2);
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
        }
    }

    .tour-info-time {
        width: 530px;
        .tour-time {
            display: flex;
            width: 100%;
            border-bottom: 1px solid rgba(52, 44, 34, 0.4);
            margin-bottom: 10px;
            img {
                margin-bottom: 30px;
            }
            .tour-time-block {
                margin-bottom: 20px;
                margin-left: 30px;
                width: 222px;
                .tour-info-timer {
                    font-size: 34px;
                    color: var(--mainColor2);
                    font-weight: 700;
                    .timer {
                        display: flex;
                        span {
                            margin-left: 10px;
                            &:first-child {
                                margin-left: 0px;
                            }
                        }
                        p {
                            margin-left: 6px;
                        }
                    }
                }
                .tour-time-days {
                    display: flex;
                    span {
                        color: var(--mainColor2);
                        margin: 0 20px;
                        text-transform: capitalize;
                        font-size: 12px;
                        &:first-child {
                            margin-left: 2px;
                        }
                    }
                    .tour-time-hours {
                        position: relative;
                        left: -2px;
                    }
                    .tour-time-min {
                        position: relative;
                        left: -10px;
                    }
                    .tour-time-sec {
                        position: relative;
                        left: -6px;
                    }
                }
            }
        }
        .tour-info-cup {
            display: flex;
            border-bottom: 1px solid rgba(52, 44, 34, 0.4);
            margin-bottom: 10px;
            .tour-cup-img {
                margin-bottom: 30px;
            }
            .tour-prize {
                display: flex;
                margin-left: 25px;
                margin-bottom: 30px;
                position: relative;
                .tour-arrow-icon {
                    position: absolute;
                    top: 20px;
                    right: -80px;
                    transform: rotate(180deg);
                    cursor: pointer;
                }
                .tour-prize-text {
                    margin-left: 10px;
                    width: 110px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    span {
                        font-size: 24px;
                        font-family: 'Roboto-slab';
                        color: var(--mainColor2);
                    }
                    p {
                        font-size: 13px;
                        font-weight: 500;
                        color: var(--mainColor2);
                        text-transform: capitalize;
                        position: relative;
                    }
                    .tour-prize-vip {
                        text-transform: uppercase;
                    }
                }
            }
        }
        .tour-swords {
            display: flex;
            .tour-swords-game {
                margin-left: 30px;
                display: flex;
                flex-direction: column;
                span {
                    font-size: 31px;
                    font-family: 'Roboto-slab';
                    color: var(--mainColor2);
                }
                p {
                    font-size: 13px;
                    color: var(--mainColor2);
                    font-weight: 500;
                    position: relative;
                    text-transform: capitalize;
                }
            }
        }
    }
}
