.user-smile-close {
    position: absolute;
    width: 750px;
    height: 585px;
    z-index: 888;
    top: 0;
}
.smiles {
    position: absolute;
    right: 0px;
    top: 65px;
    height: 540px;
    z-index: 1;
    width: 213px;
    border-radius: 10px;

    .smiles-user {
        background: linear-gradient(#4b0000 0%, #740017 100%);
        height: 540px;
        right: -200px;
        width: 213px;
        position: absolute;
        transition: right 0.5s ease-in-out;
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;

        box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.5);
        .background_popup {
            border-radius: 0px;
            border-bottom-left-radius: 16px;
            border-top-left-radius: 16px;
            height: 540px;
            .background_pattern_popup {
                background-position: -142px -272px;
                background-size: 330% 266%;
                p {
                    color: #fff;
                    padding-top: 16px;
                    text-transform: capitalize;
                    font-size: 23px;
                    font-weight: 700;
                    padding-left: 15px;
                    padding-bottom: 15px;
                }
                .menu-gradline {
                    margin-left: 15px;
                    width: 86px;
                    height: 1px;
                    background: linear-gradient(
                        to right,
                        #fff700 0%,
                        rgba(97, 0, 0, 0)
                    );
                }
                .smiles-block {
                    margin-top: 20px;
                    margin-left: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    height: 450px;
                    overflow-y: scroll;
                    /* width */

                    .smile-block {
                        width: 82px;
                        height: 82px;
                        margin: 0 5px;
                        margin-bottom: 5px;
                        cursor: pointer;
                        border-radius: 10px;
                        background: rgba(0, 0, 0, 0.3);
                        &:hover {
                            background: var(--mainColor33);
                            box-shadow: 0px 0px 6px #ff0000;
                        }
                    }
                }
            }
        }
    }
    &.open {
        .smiles-user {
            right: 0;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    // ::-webkit-scrollbar-track {
    //     background: linear-gradient(#fff 0%, #a2a2a2 100%);
    // }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#fff 70%, #a2a2a2 100%);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#fff 0%, #a2a2a2 100%);
    }
}
