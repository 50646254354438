.tournament-dashboard-page {
    .tournament-bg {
        .tournament-bg-block {
            background: radial-gradient(#fff 0%, #848484 100%);
        }
        .tournament-bg-pattern {
            background-position: 330px -70px;
            background-size: 100% 100%;
            .tournament-dashboard-wrap {
                display: flex;
                height: 620px;
                .tournament-dashboard {
                    position: relative;
                    top: 100px;
                    width: 485px;
                    height: 520px;
                    .dashboard-wrapper {
                        width: 100%;
                        background: radial-gradient(
                            rgba(0, 0, 0, 0.3) 50%,
                            var(--mainColor26) 150%
                        );
                        height: 445px;
                        border-top-right-radius: 15px;
                    }
                    ::-webkit-scrollbar {
                        width: 5px;
                    }

                    /* Track */
                    // ::-webkit-scrollbar-track {
                    //     background: linear-gradient(#fff 0%, #a2a2a2 100%);
                    // }

                    /* Handle */
                    ::-webkit-scrollbar-thumb {
                        background: linear-gradient(#fff 70%, #a2a2a2 100%);
                        border-radius: 5px;
                    }

                    /* Handle on hover */
                    ::-webkit-scrollbar-thumb:hover {
                        background: linear-gradient(#fff 0%, #a2a2a2 100%);
                    }
                }
            }
        }
    }
}
