.lobby_bet {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    position: relative;
    p {
        text-transform: uppercase;
        color: var(--mainColor19);
        font-weight: 700;
        font-size: 12px;
    }
    span {
        text-transform: uppercase;
        color: var(--mainColor19);
        font-weight: 700;
    }
    img {
        margin-bottom: 5px;
    }
}
