$game-height: 620px;
$game-width: 944px;
#game-info {
    background: #2a0e0b;
    margin-top: 0px;
    width: $game-width;
    height: 565px;
    position: relative;
    .game_info_bg {
        border: 2px solid var(--mainColor5);
        border-bottom-right-radius: 14px;
        border-top: none;
        border-bottom-left-radius: 14px;
    }
}
#game-play-cards {
    margin-top: 40px;
}
#game-play {
    position: relative;
    background: radial-gradient(#009e33 0%, #003b00 100%);
    z-index: 1;
    margin-top: 40px;
    width: 1000px;
    height: 580px;
    .game-bg-img {
        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: -1;
        }
    }
}
