.dashboard-header {
    display: flex;
    margin-left: 22px;
    height: 60px;
    margin-bottom: 15px;
    justify-content: space-between;
    .tour-avatar-img-block {
        display: flex;
        .tour-avatar {
            width: 60px;
            img {
                border-radius: 50%;
                width: 100%;
            }
            .tour-level {
                width: 26px;
                height: 26px;
                border: 1px solid #f8c48a;
                background: #430000;
                border-radius: 50%;
                position: relative;
                top: -25px;
                left: 40px;
                span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    color: var(--mainColor18);
                    font-size: 12px;
                    font-weight: 700;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .tour-my-score {
            margin-left: 17px;
            border-right: 1px solid #8e7c63;
            width: 128px;
            span {
                color: var(--mainColor2);
                font-size: 14px;
            }
            p {
                color: var(--mainColor2);
                font-size: 25px;
            }
        }
    }
    .tour-border {
        border: 1px solid #8e7c63;
        margin-left: 26px;
        margin-right: 22px;
        height: 55px;
    }
    .tour-dashboard-position {
        display: flex;

        .my-position,
        .my-position-lang {
            border-left: 1px solid #3e362b;
            width: 110px;
            padding-left: 30px;
            color: var(--mainColor2);
            span {
                font-size: 14px;
                font-family: 'Mardoto';
            }
            p {
                font-size: 25px;
            }
            &.my-position-lang {
                padding-left: 20px;
            }
        }
        .tour-dashboard-reward {
            display: flex;
            width: 134px;
            height: 46px;
            background: #443a2b;
            border-radius: 8px;
            margin-left: 25px;
            cursor: pointer;
            align-items: center;
            position: relative;
            overflow: hidden;
            .tour-reward-img {
                display: flex;
                .tour-bg-img {
                    position: absolute;
                    left: 10px;
                    top: 6px;
                    width: 36px;
                    height: 36px;
                    background: linear-gradient(#fff08b 0%, #fc9000 100%);
                    border-radius: 50%;

                    &.tour-animate-rewards {
                        animation: tourMyReward 6s infinite backwards;
                        animation-delay: 3s;
                    }
                }
                .tour-img-rewards {
                    width: 50%;
                    left: 12px;
                    position: relative;
                }
                .tour-img-rewards-animate {
                    width: 50%;
                    animation: tourImgReward 6s infinite backwards;
                    animation-delay: 3s;
                    left: 10px;
                    position: relative;
                    transform: scale(1);
                }
            }
            .tour-my-reward {
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                right: 10px;
                span {
                    font-size: 10px;
                    font-weight: 500;
                    color: var(--mainColor2);
                    text-align: right;
                }
                p {
                    font-size: 16px;
                    font-weight: 700;
                    color: var(--mainColor2);
                    text-transform: uppercase;
                }
            }
        }
    }
}
@keyframes tourImgReward {
    0% {
        transform: scale(1);
    }
    60% {
        transform: scale(1);
    }
    90% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes tourMyReward {
    0% {
        width: 38px;
        height: 38px;
        left: 10px;
        top: 6px;
    }
    60% {
        width: 38px;
        height: 38px;
        left: 10px;
        top: 6px;
    }
    90% {
        width: 300px;
        height: 300px;
        left: -20px;
        top: -150px;
    }
    100% {
        width: 38px;
        height: 38px;
        left: 10px;
        top: 6px;
    }
}
