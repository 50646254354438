.tournament-result {
    width: 865px;
    height: 434px;
    position: relative;
    top: -50px;
    .tournament-result-tab {
        background: rgba(255, 248, 219, 0.5);
        height: 60px;
        position: absolute;

        z-index: 2;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .tournament-result-tab-index {
            position: absolute;
            right: 100px;
            top: 5px;
            span {
                font-size: 20px;
                color: var(--mainColor2);
                font-family: 'Mardoto';
                font-weight: 700;
                padding: 7px 11px;
                top: 7px;
                position: relative;
            }
            .active {
                background: #635441;
                border-top-left-radius: 11px;
                border-top-right-radius: 11px;
                position: relative;
                padding: 7px 11px;
                top: 7px;
            }
        }
        img {
            position: absolute;
            right: 12px;
            top: 12px;
            cursor: pointer;
        }
    }
    .tournament-result-block {
        height: 394px;
        position: relative;
        z-index: 3;
        top: 40px;
        .tournament-bg {
            height: 394px;
            .tournament-bg-block {
                width: 865px;
                display: none;
            }
            .tournament-result-content {
                .tournament-result-title {
                    padding-top: 19px;
                    padding-left: 20px;

                    span {
                        display: block;
                        font-size: 30px;
                        color: var(--mainColor2);
                        font-family: 'Mardoto';
                        font-weight: 700;
                        padding-bottom: 15px;
                    }
                    .menu-gradline {
                        width: 86px;
                        height: 1px;
                        background: linear-gradient(
                            to right,
                            #fff700 0%,
                            rgba(97, 0, 0, 0)
                        );
                    }
                }
                .tournament-result-section {
                    display: flex;
                    .tournament-result-right-block {
                        position: relative;
                        .result-block {
                            height: 230px;
                            overflow-y: scroll;
                            .more-level {
                                width: 370px;
                                height: 64px;
                                background: linear-gradient(
                                    to right,
                                    #3d9198 0%,
                                    #1f5252 100%
                                );
                                border-radius: 5px;
                                display: flex;
                                align-items: center;

                                position: absolute;
                                right: 4px;
                                bottom: 120px;
                                z-index: 9;
                                .more-level-bottom {
                                    position: relative;
                                    background: #0a2b27;
                                    height: 74px;
                                    width: 370px;
                                }
                                .unlock-text {
                                    display: flex;
                                    flex-direction: column;
                                    padding-left: 5px;
                                    span {
                                        font-size: 18px;
                                        font-family: 'Mardoto';
                                        font-weight: bold;
                                        color: var(--mainColor2);
                                    }
                                }
                                .unlock-btn {
                                    margin-left: 40px;
                                    padding-left: 40px;
                                    color: var(--mainColor2);
                                    font-family: 'Mardoto';
                                    font-weight: bold;
                                    text-transform: uppercase;
                                    letter-spacing: 0.6px;
                                    border-left: 1px solid #2f7478;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    // ::-webkit-scrollbar-track {
    //     background: linear-gradient(#fff 0%, #a2a2a2 100%);
    // }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#fff 70%, #a2a2a2 100%);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#fff 0%, #a2a2a2 100%);
    }
}
