.bazar-window {
    width: 620px;
    height: 170px;
    border: 1px solid var(--mainColor1);
    border-radius: 10px;
    position: absolute;
    background: var(--mainColor8);
    top: calc(50% - 85px);
    left: calc(50% - 310px);
    padding: 0 20px;
    z-index: 1;
    .bazar-say-score {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        .say-score-block {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 238px;
            height: 60px;
            border: 2px solid var(--mainColor1);
            border-radius: 8px;
            .say-score {
                display: flex;
                width: 100%;
                justify-content: space-between;
                position: relative;
                .icon-Minus,
                .icon-Plus {
                    width: 40px;
                    height: 40px;
                    background: var(--mainColor1);
                    border-radius: 10px;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 24px;
                }
                .icon-Minus {
                    margin-left: 10px;
                }
                .icon-Plus {
                    margin-right: 10px;
                }
                .icon-text {
                    font-size: 39px;
                    color: var(--mainColor1);
                    font-weight: 700;
                }
                .icon-text-capout {
                    font-size: 39px;
                    color: var(--mainColor1);
                    font-weight: 700;
                    position: absolute;
                    left: 70px;

                    .capout-text {
                        font-size: 36px;
                        position: absolute;
                        top: 5px;
                        left: 45px;
                        font-weight: 700;
                    }
                }
            }
        }
        .iscapout-button {
            position: relative;
            top: 5px;
            left: 10px;
            .button-tree {
                height: 46px;
                button {
                    background: #e6bc95;
                    margin-right: 10px;
                    width: 110px;
                    span {
                        font-size: 15px;
                        color: #000;
                    }
                }
                .button-tree-under {
                    background: #341d1c;
                    position: absolute;
                    bottom: 6px;
                    width: 110px;
                }
            }
        }
        .capout-text-block {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 110px;
            height: 60px;
            background: var(--mainColor9);
            border-radius: 10px;
            border-bottom: 4px solid var(--mainColor11);
            cursor: auto;
            span {
                font-family: 'Roboto';
                font-weight: 700;
                font-size: 22px;
            }
        }
    }
    .bazar-suit {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        width: 75%;
    }
    .bazar-say-button {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .contra-button {
            opacity: 1;
            position: absolute;
            right: 12px;
            top: 12px;
            &:first-child {
                right: 90px;
            }

            .in-active {
                width: 63px;
                height: 63px;
                border-radius: 50%;
                background: rgba(238, 201, 158, 0.2);
                span {
                    color: #000;
                    font-size: 38px;
                    opacity: 1;
                    font-weight: 700;
                    font-family: 'Roboto';
                }
            }
            .say-button {
                width: 63px;
                height: 63px;
                border-radius: 50%;
                background: var(--mainColor1);
                span {
                    color: #000;
                    font-size: 38px;
                    opacity: 1;
                    font-weight: 700;
                    font-family: 'Roboto';
                }
                &:hover {
                    background: var(--mainColor2);
                }
            }
        }

        // .isrecontra-button {
        //     width: 63px;
        //     height: 63px;
        //     border-radius: 50%;
        //     background: rgba(238, 201, 158, 0.2);

        //     position: absolute;
        //     right: 12px;
        //     top: 12px;

        //     span {
        //         color: #000;

        //         opacity: 1;
        //         font-size: 38px;
        //         font-weight: 700;
        //         font-family: 'Roboto';
        //         position: absolute;
        //         left: 10px;
        //         top: 8px;
        //     }

        //     .in-active {
        //         width: 63px;
        //         height: 63px;
        //         border-radius: 50%;
        //         background: rgba(238, 201, 158, 0.2);
        //         span {
        //             color: #000;
        //             font-size: 38px;
        //             opacity: 1;
        //             font-weight: 700;
        //             font-family: 'Roboto';
        //         }
        //     }
        //     .say-button-recontra {
        //         width: 63px;
        //         height: 63px;
        //         border-radius: 50%;
        //         background: var(--mainColor1);
        //         span {
        //             color: #000;
        //             font-size: 38px;
        //             opacity: 1;
        //             font-weight: 700;
        //             font-family: 'Roboto';
        //         }
        //     }
        // }
        .pass-button {
            height: 50px;
            width: 150px;
            background: linear-gradient(#ecc69c 0%, #94765b 100%);
            border-radius: 10px;
            cursor: pointer;
            span {
                font-size: 20px;
                font-family: 'Roboto';
                font-weight: 700;
                color: #000;
                text-align: center;
                padding-top: 12px;
                display: block;
            }
            &:hover {
                background: #ffecb7;
            }
        }
    }
    .contra-button {
        opacity: 0.16;
        cursor: auto;
        span {
            opacity: 0.3;
        }
    }
    // .isrecontra-button {
    //     opacity: 0.16;
    //     cursor: auto;
    //     span {
    //         opacity: 0.3;
    //     }
    // }
    .contra-default {
        button {
            background: rgba(238, 201, 158, 0.2) !important;
        }
    }
}
