.gift-click-out {
    position: absolute;
    width: 700px;
    height: 585px;
    z-index: 888;
    top: 0;
}
.gift-block {
    width: 300px;
    position: absolute;
    right: 0;
    z-index: 1;
    height: 540px;
    top: 65px;
    .gift-animate-block {
        width: 300px;
        height: 540px;
        position: absolute;
        background: linear-gradient(#4b0000 0%, #740017 100%);
        right: -300px;
        transition: right 0.5s ease-in-out;
        border-bottom-left-radius: 16px;
        border-top-left-radius: 16px;
        box-shadow: -6px 0px 20px rgba(0, 0, 0, 0.5);
        .background_popup {
            border-radius: 0px;
            border-bottom-left-radius: 16px;
            border-top-left-radius: 16px;
            height: 540px;
            .background_pattern_popup {
                background-position: -142px -272px;
                background-size: 330% 266%;
                .gifts_text {
                    font-size: 23px;
                    font-weight: 700;
                    text-transform: capitalize;
                    padding-left: 15px;
                    color: var(--mainColor2);
                    padding-top: 15px;
                    padding-bottom: 20px;
                }
                .menu-gradline {
                    margin-left: 20px;
                    width: 86px;
                    height: 1px;
                    background: linear-gradient(
                        to right,
                        #fff700 0%,
                        rgba(97, 0, 0, 0)
                    );
                }
                .gift_block {
                    margin-top: 5px;
                    overflow-y: scroll;
                    height: 460px;
                    .gift {
                        width: 120px;
                        height: 120px;
                        background: rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        position: relative;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        float: left;
                        margin: 10px;
                        margin-left: 15px;
                        .gift-img {
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            margin: auto;
                            width: 90px;
                        }
                        .gift-vip {
                            width: 34px;
                            height: 34px;
                            position: absolute;
                            right: -12px;
                            top: -12px;
                            img {
                                width: 100%;
                            }
                        }
                        .gift-currency {
                            background: #430000;
                            width: 58px;
                            height: 27px;
                            border-radius: 13px;
                            position: absolute;
                            display: flex;
                            justify-content: space-around;
                            bottom: -13px;
                            left: 0;
                            right: 0;
                            margin: auto;
                            img {
                                width: 20px;
                                height: 20px;
                                position: absolute;
                                top: 4px;
                                left: 3px;
                            }
                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--mainColor2);
                                font-size: 18px;
                                font-weight: 700;
                                position: absolute;
                                right: 8px;
                                top: 4px;
                            }
                        }
                        &:hover {
                            background: var(--mainColor33);
                            box-shadow: 0px 0px 6px #ff0000;
                        }
                    }
                }
            }
        }
    }

    &.open {
        .gift-animate-block {
            right: 0;
        }
    }
    &.close {
        .gift-animate-block {
            right: -200px;
        }
    }
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    // ::-webkit-scrollbar-track {
    //     background: linear-gradient(#fff 0%, #a2a2a2 100%);
    // }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: linear-gradient(#fff 70%, #a2a2a2 100%);
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(#fff 0%, #a2a2a2 100%);
    }
}
