.tour-rewards-line {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(52, 44, 34, 0.4);
    margin-bottom: 15px;
    &:last-child {
        border-bottom: 1px solid transparent;
    }
    .rewards-left-block {
        display: flex;
        margin-left: 40px;
        width: 33%;
        position: relative;
        .item-img {
            position: relative;
            left: -15px;
        }
        .rewards-img {
            font-family: 'DejaVu-Sans-bold';
            color: var(--mainColor2);
            font-size: 20px;
            font-weight: bold;
        }
        .last-reward-user {
            span {
                font-size: 20px;
                color: var(--mainColor2);
                font-weight: 700;
                padding-left: 20px;
            }
        }
        .last-reward-user:hover{
            cursor: pointer;
            .tour-rewards-tooltip{
                display: block;
            }
        }
        .user-navigator {
            position: absolute;
            left: -40px;
            bottom: 5px;
            width: 30px;
        }
    }
    .rewards-center-block{
        display: flex;
        width: 33%;
        justify-content: flex-end;
        margin-bottom: 10px;
        .reward-red-block{
            display: flex;
            align-items: center;
            position: relative;
            span{
                font-family: "Mardoto-Bold", sans-serif;
                font-size: 22px;
                color: var(--mainColor2);
                font-weight: bold;
            }
            img{
                width: 20px;
            }
            .red-first{
                display: flex;
                align-items: center;
                position: relative;
                margin-left: 50px;
                .red-first-img-first{
                    position: absolute;
                    right: 0;
                }
                .red-first-img-second{
                    position: absolute;
                    right: 10px;
                }
                .red-first-img-third{
                    position: absolute;
                    right: 20px;
                }
                .red-first-img-four{
                    position: absolute;
                    right: 30px;
                }
            }
            .red-second{
                display: flex;
                align-items: center;
                position: relative;
                margin-left: 40px;
                .red-second-img-first{
                    position: absolute;
                    right: 0;
                }
                .red-second-img-second{
                    position: absolute;
                    right: 10px;
                }
                .red-second-img-third{
                    position: absolute;
                    right: 20px;
                }
            }
            .red-third{
                display: flex;
                align-items: center;
                position: relative;
                margin-left: 30px;
                .red-third-img-first{
                    position: absolute;
                    right: 0;
                }
                .red-third-img-second{
                    position: absolute;
                    right: 10px;
                }
            }
        }
    }
    .rewards-right-block{
        width: 33%;
    }
}
