.user-recontra {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    background: red;
    top: -20px;
    left: 135px;
    span {
        position: absolute;
        top: 4px;
        left: 4px;
        right: 0;
        bottom: 0;
        margin: auto;
        color: var(--mainColor2);
        font-size: 16px;
        font-weight: 700;
    }
}
.user-recontra-4 {
    left: 47px;
    top: -35px;
}
.user-recontra-3 {
    left: 175px;
    top: 100px;
}
.user-recontra-2 {
    left: 44px;
    top: -37px;
}
.user-recontra-1 {
    left: 48px;
    top: -35px;
}
