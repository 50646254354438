.background_popup {
    background-image: url('../../../assets/images/elipse.svg');
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    border-radius: 15px;
    height: 438px;
    background: radial-gradient(
        ellipse at center,
        var(--mainColor32) 40%,
        var(--mainColor30) 100%
    );
    .background_block_popup {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 1000px;
        height: 100%;

        mix-blend-mode: multiply;
    }
    .background_pattern_popup {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-image: url('../../../assets/images/blot_bg_img.svg');
        background-repeat: no-repeat;
        background-position: -440px -281px;
        background-size: 140% 214%;
    }
}
