.tournament-game-block {
    display: flex;
    flex-wrap: wrap;
    width: 520px;

    .tournament-game,
    .tournament-game-count,
    .tournament-game-mini {
        width: 130px;
        height: 130px;
        border-radius: 16px;
        background: rgba(70, 60, 46, 0.44);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px;
        flex-direction: column;

        position: relative;

        span {
            font-weight: 700;
            font-size: 26px;
            color: var(--mainColor2);
            width: 50px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;
        }
        .point-active {
            font-size: 22px;
        }
        p {
            color: var(--mainColor2);
            font-size: 12px;
            position: absolute;
            bottom: 12px;
            left: 16px;
        }
    }
    .tournament-game-mini {
        width: 130px;
        height: 130px;
        border: 2px solid transparent;
        box-shadow: 0px 0px 7px rgba(232, 173, 12, 0.62);
        margin: 20px;
        margin-top: 60px;
    }
}
.tournament-game-block {
    .tournament-game {
        // background: url('../../../../assets/images/tournament/dashboard-little-img.png');
        // background-repeat: no-repeat;
        // background-size: 100% 99.4%;
        // box-shadow: 0px 0px 7px rgba(232, 173, 12, 0.62);
        text-align: center;
    }
    .tournament-game-active {
        background: url('../../../../assets/images/tournament/dashboard-little-img.png');
        background-repeat: no-repeat;
        background-size: 100% 99.4%;
        box-shadow: 0px 0px 7px rgba(232, 173, 12, 0.62);
    }
}

.tournament-game {
    border: 2px solid #ffcf86;
}
.tournament-active-index {
    height: 100px;
    background: transparent;
    border: 1px solid #ffcf86;
    border-radius: 20px;
    display: flex;
    cursor: pointer;
    .tournament-game-count {
        box-shadow: transparent;
        animation: activeIndex 1s ease-in-out infinite;
    }
}
.abgar {
    cursor: pointer;
    border: 1px solid #ffcf86;
    margin-left: 10px;
    border-radius: 15px;
    width: 154px;
    margin-top: 8px;
    height: 154px;
    .tournament-game-active {
        margin: 10px;
    }
}

@keyframes activeIndex {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}
