.popup_base {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    .popup_base_inner {
        position: absolute;
        left: 0;
        top: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 540px;
    }
    .dark_back {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        top: 0;
    }
    .popup {
        z-index: 2;
    }
}
