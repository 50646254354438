.tournament-register-timer {
    position: absolute;
    top: 110px;
    left: 15px;
    .tournament-timer-block {
        display: flex;
        font-family: 'Roboto-slab';
        font-size: 28px;
        color: var(--mainColor2);
        .timer {
            display: flex;
            span {
                font-family: 'Roboto-slab';
                padding-left: 5px;
                &:first-child {
                    padding-left: 0px;
                }
            }
            p {
                font-family: 'Roboto-slab';
                padding-left: 10px;
                &:first-child {
                    padding-left: 0px;
                }
            }
        }
    }
    .tournament-text-block {
        span {
            font-size: 12px;
            color: var(--mainColor2);
            font-weight: 500;
            padding-left: 27px;
            &:first-child {
                padding-left: 0px;
            }
        }
        .tournament-day-text {
            position: relative;
            left: 2px;
        }
        .tournament-min-text {
            position: relative;
            left: -4px;
        }
    }
}
