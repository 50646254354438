.tournament-bg {
    // background-image: url('../../../assets/images/elipse-tour.png');
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    height: 620px;
    background: radial-gradient(ellipse at center, #b59a74 40%, #504435 100%);
    .tournament-bg-block {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 1000px;
        height: 100%;
        background: radial-gradient(#fff 0%, #848484 100%);
        mix-blend-mode: multiply;
    }
    .tournament-bg-pattern {
        background-position: -4px -460px;
        background-size: 133% 252%;
        &::before {
            content: '';
            opacity: 0.6;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            // background-image: url('../../../assets/images/tour-pattern.png');
            background-repeat: no-repeat;
        }
    }
}
