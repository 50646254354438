.tour-header-info {
    position: absolute;
    left: 41.5%;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    width: 58.5%;
    height: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    .tour-header-text {
        padding-top: 30px;
        span {
            color: rgba(255, 255, 255, 0.1);

            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
            font-size: 21px;
        }
    }
    .text-active {
        span {
            color: var(--mainColor2);
        }
    }
    &:before {
        content: '';
        width: 148px;
        height: 4px;
        background: var(--mainColor2);
        position: absolute;
        bottom: 0px;
        transform: translateX(0);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        left: 10px;
        transition: 0.4s all ease-out;
        z-index: 1;
    }
}
.tour-header-info-0 {
    &::before {
        transform: translateX(0);
    }
}
.tour-header-info-1 {
    &::before {
        transform: translateX(120%);
    }
}
.tour-header-info-2 {
    &::before {
        transform: translateX(260%);
    }
}
