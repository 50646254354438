$menu-height: 540px;
$menu-width: 213px;

.menu_game_play {
    position: absolute;
    right: 10px;
    top: 65px;
    top: 72px;
    width: 213px;
    height: 250px;
    background: linear-gradient(#740319 0%, #2c0101 150%);
    border-radius: 15px;
    z-index: 1;
    .menu_angle {
        position: absolute;
        top: -29px;
        right: 10px;
    }
    .menu_game_play_text {
        font-size: 22px;
        font-weight: 700;
        color: var(--mainColor2);
        text-transform: uppercase;
        padding: 16px;
    }
    .menu_game_play_item {
        .menu_item,
        .menu_item_active {
            display: flex;
            align-items: center;
            height: 64px;
            background: rgba(255, 255, 255, 0.13);
            width: 100%;
            .menu_item_block {
                display: flex;
                align-items: center;
                margin-left: 28px;
                width: 100px;
                .menu_item_text,
                .menu_item_text_active {
                    text-transform: capitalize;
                    font-weight: 500;
                    font-size: 16px;
                    padding-left: 10px;
                    color: #570008;
                }
            }
            .toggle {
                .toggle_in {
                    width: 34px;
                    height: 16px;
                    border-radius: 15px;
                    margin-left: 45px;
                    background-color: var(--mainColor2);

                    border: 1px solid #7e0000;
                    position: relative;
                    transition: background-color 0.3s ease-in-out;
                    .toggle_active {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;

                        background: linear-gradient(
                            to top,
                            #5f5d5d 0%,
                            #a3a3a3 100%
                        );
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
                        position: absolute;
                        top: 1px;
                        left: 2px;
                        transition: left 0.3s ease-in-out;
                        &.icon {
                            left: 18px;
                        }
                    }
                }
            }
        }
        .menu {
            height: $menu-height;
            width: $menu-width;
            background: linear-gradient(#20933e 15%, #003a0f 100%);
            height: $menu-height;
            width: $menu-width;
            position: absolute;
            top: 80px;
            right: -($menu-width);
            transition: right 0.3s ease-in-out;
            &.open {
                right: 0;
            }
            .menu_background {
                background-image: url('../../assets/images/blot_bg_img.svg');
                background-repeat: no-repeat;
                background-size: 620% 255%;
                height: 540px;
                background-position: -280px -439px;
                .menu_text {
                    display: flex;
                    flex-direction: column;
                    margin: 0 12px;
                    p {
                        color: #2d2d2d;
                        font-size: 20px;
                        font-weight: 700;
                        text-transform: capitalize;
                        width: 176px;
                        padding-bottom: 15px;
                        padding-top: 12px;
                    }
                    img {
                        width: 70px;
                    }
                }

                .menu_settings_block {
                    position: relative;
                    top: 190px;
                    p {
                        color: #2d2d2d;
                        font-size: 22px;
                        font-weight: 700;
                        padding-left: 12px;
                        text-transform: uppercase;
                        margin-bottom: 13px;
                    }
                }
                .menu_home {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: 35px;
                    right: 26px;
                    img {
                        margin-right: 5px;
                    }
                    .menu_leave {
                        color: var(--mainColor2);
                        font-size: 16px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
            }
        }
        .menu_item_active {
            background: rgba(255, 255, 255, 0.36);
            border-radius: 0px;
            margin-bottom: 0px;
            .toggle {
                margin-left: 0px;
                .toggle_in {
                    border: 1px solid var(--mainColor22);
                    .toggle_active {
                        background: linear-gradient(#289718 0%, #144c0c 100%);
                        left: 18px;
                    }
                }
            }
        }

        .menu_item_active {
            .menu_item_block {
                .menu_item_text_active {
                    color: var(--mainColor2);
                }
            }
        }
    }
    .menu_game_play_home {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
        margin-right: 20px;
        cursor: pointer;
        img {
            margin-right: 10px;
        }
        span {
            font-weight: 500;
            font-size: 16px;
            text-transform: capitalize;
            color: var(--mainColor2);
            letter-spacing: 0.7px;
        }
    }
}
