.send-gift {
    position: absolute;
    width: 90px;
    height: 90px;
    img {
        width: 90px;
    }
    &.from-0-to-3 {
        animation: fromzrofirst 1s both;
    }
    &.from-0-to-2 {
        animation: fromzrotwo 1s both;
    }
    &.from-0-to-1 {
        animation: fromzrothree 1s both;
    }
    &.from-0-to-0 {
        animation: fromzroZro 1s both;
    }

    &.from-2-to-0 {
        animation: fromTwoZro 1s both;
    }
    &.from-2-to-3 {
        animation: fromTwoFirst 1s both;
    }
    &.from-2-to-1 {
        animation: fromTwoThird 1s both;
    }
    &.from-2-to-2 {
        animation: fromTwoTwo 1s both;
    }

    &.from-1-to-0 {
        animation: fromFirstZro 1s both;
    }
    &.from-1-to-3 {
        animation: fromFirstFirst 1s both;
    }
    &.from-1-to-1 {
        animation: fromFirstThird 1s both;
    }
    &.from-1-to-2 {
        animation: fromFirstTwo 1s both;
    }

    &.from-3-to-0 {
        animation: fromThirdZro 1s both;
    }
    &.from-3-to-3 {
        animation: fromThirdFirst 1s both;
    }
    &.from-3-to-1 {
        animation: fromThirdThird 1s both;
    }
    &.from-3-to-2 {
        animation: fromThirdTwo 1s both;
    }
}

@keyframes fromThirdZro {
    0% {
        top: 210px;
        left: 750px;
    }

    100% {
        top: 400px;
        left: 50px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromThirdFirst {
    0% {
        top: 210px;
        left: 750px;
    }

    100% {
        top: 210px;
        left: 160px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromThirdTwo {
    0% {
        top: 210px;
        left: 750px;
    }

    100% {
        top: 50px;
        left: 640px;
        width: 110px;
        transition: ease-in-out;
    }
}

@keyframes fromFirstFirst {
    0% {
        top: 210px;
        left: 160px;
    }
    100% {
        top: 210px;
        left: 160px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromzroZro {
    0% {
        bottom: 0;
        left: 0;
    }
    100% {
        bottom: 90px;
        left: 50px;
    }
}
@keyframes fromFirstThird {
    0% {
        top: 210px;
        left: 160px;
    }
    100% {
        top: 220px;
        left: 760px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromFirstTwo {
    0% {
        top: 210px;
        left: 160px;
    }
    100% {
        top: 50px;
        left: 640px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromFirstZro {
    0% {
        top: 210px;
        left: 160px;
    }

    100% {
        top: 400px;
        left: 60px;
        width: 110px;
        transition: ease-in-out;
    }
}

@keyframes fromTwoTwo {
    0% {
        top: 50px;
        right: 250px;
    }
    100% {
        top: 50px;
        right: 250px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromTwoThird {
    0% {
        top: 50px;
        right: 250px;
    }

    100% {
        top: 200px;
        right: 150px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromTwoZro {
    0% {
        top: 50px;
        right: 250px;
    }

    100% {
        bottom: 90px;
        left: 50px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromTwoFirst {
    0% {
        top: 50px;
        right: 250px;
    }

    100% {
        top: 230px;
        left: 150px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromzrofirst {
    0% {
        bottom: 0;
        left: 0;
    }

    100% {
        bottom: 280px;
        left: 160px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromzrothree {
    0% {
        bottom: 0;
        left: 0;
    }

    100% {
        bottom: 275px;
        left: 760px;
        width: 110px;
        transition: ease-in-out;
    }
}
@keyframes fromzrotwo {
    0% {
        bottom: 0;
        left: 0;
    }
    100% {
        bottom: 440px;
        left: 640px;
        width: 110px;
        transition: ease-in-out;
    }
}
