.background {
  background-image: url('../../assets/images/elipse.svg');
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  height: 620px;
  background: radial-gradient(ellipse at center, #97001f 40%, #6a0000 100%);
  .background_block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 1000px;
    height: 100%;
    background: radial-gradient(
      63.28% 46.28% at 51.46% 50%,
      #fffffe 0%,
      rgba(0, 0, 0, 0.33) 88%
    );

    mix-blend-mode: multiply;
  }
  .background_pattern {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-image: url('../../assets/images/blot_bg_img.svg');
    background-repeat: no-repeat;
    background-position: -4px -460px;
    background-size: 133% 252%;
  }
}
