$app_width: 1000px;
$app_height: 620px;

.app {
    width: 1000px;
    height: 620px;
    margin: 0 auto;
    position: relative;
    .page {
        width: 100%;
        height: 100%;
    }
}
