.user-login-first {
    width: 1000px;
    height: 620px;
    display: flex;
    .background {
        width: 1000px;
        .background_block {
            background: transparent;
        }
        .user-login-first-content {
            display: flex;
            width: 1000px;
            height: 620px;
            .user-login-left {
                width: 40%;
                background: linear-gradient(to right, #6a0000 0%, #ab0023 100%);
                box-shadow: 12px 0px 12px rgba(0, 0, 0, 0.16);
                .user-login-left-belot-logo {
                    width: 250px;
                    margin: 0 auto;
                    display: flex;
                    justify-content: center;
                    height: 100%;
                    img {
                        width: 100%;
                    }
                }
            }
            .user-login-right {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                width: 60%;
                .nickname {
                    color: var(--mainColor2);
                    font-size: 24px;
                    padding-bottom: 20px;
                    padding-left: 50px;
                    font-family: 'Roboto';
                    font-weight: 700;
                    text-transform: capitalize;
                    letter-spacing: 1px;
                }
                input {
                    background: rgba(0, 0, 0, 0.4);
                    width: 70%;
                    margin-left: 50px;
                    height: 50px;
                    border-radius: 10px;

                    color: var(--mainColor2);
                    font-size: 20px;
                    letter-spacing: 1.2px;
                    padding-left: 14px;
                    &::placeholder {
                        color: var(--mainColor2);
                        text-transform: capitalize;
                    }
                }
                .user-login-btn-block {
                    position: absolute;
                    bottom: 30px;
                    display: flex;
                    right: 20px;
                    span {
                        font-size: 16px;
                    }
                    .button-tree-under {
                        bottom: -5px;
                    }
                    .user-login-cancel {
                        margin-right: 20px;
                        position: relative;
                        height: 48px;
                        .button-tree {
                            button {
                                background: #500000;
                            }
                            .button-tree-under {
                                background: #440003;
                            }
                        }
                    }
                    .user-login-confirm {
                        position: relative;
                        height: 48px;
                    }
                }
            }
        }
    }
}
