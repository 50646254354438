$loading_height: 620px;
#loading-page {
    position: relative;
    background: radial-gradient(ellipse at center, #97001f 0, #6a0000 100%);
    .loading_wrapper {
        position: absolute;
        left: 0;
        top: 175px;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 300px;
        height: 372px;
        z-index: 1;
        .logo {
            display: block;
            margin: 0 auto;
            margin-bottom: 112px;
        }
    }
    .loading_bg {
        border: 1px solid rgba(255, 145, 145, 0.16);
        width: 1000px;
        position: absolute;
        bottom: 99px;
    }
    .language_bg_border {
        border: 1px solid #610000;
        width: 1000px;
        position: absolute;
        bottom: 101px;
    }
}
