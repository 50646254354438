.tour-game-time {
    position: absolute;
    top: 50px;
    .tour-game-joined {
        position: absolute;
        height: 27px;
        background: #bf1620;
        border-radius: 4px 4px 4px 0;
        display: flex;
        align-items: center;
        top: 25px;
        width: 134px;
        left: -20px;
        img {
            width: 20px;
            position: relative;
        }
        span {
            font-size: 14px;
            color: var(--mainColor2);
            text-transform: capitalize;
            position: relative;
            font-family: 'Mardoto';
            font-weight: bold;
        }
    }
    .tour-game-joined-time {
        display: flex;
        width: 260px;
        height: 40px;
        position: relative;
        top: 60px;
        left: -20px;

        .tour-game-time-item {
            .time-item {
                display: flex;
                align-items: center;
                .tour-time-item {
                    width: 48px;
                    height: 48px;
                    .timer {
                        display: flex;
                        color: var(--mainColor2);
                        font-size: 34px;
                        p {
                            margin: 0 6px;
                            font-family: 'Roboto-slab';
                        }
                        span {
                            font-family: 'Roboto-slab';
                            display: flex;
                            padding: 0 6px;
                            align-items: center;
                            justify-content: center;
                            box-shadow: 0px 0px 24px var(--mainColor2);
                            border-radius: 9px;
                            background: linear-gradient(
                                #262626 0%,
                                #808080 50%,
                                #212121 50%,
                                #7b7b7b 100%
                            );
                        }
                    }
                }
            }
            .tour-time-text-block {
                display: flex;
                .tour-time-text-sec {
                    position: relative;
                }
                .tour-time-text-min {
                    position: relative;
                    left: -6px;
                }
                p {
                    font-size: 12px;
                    font-weight: 500;
                    color: var(--mainColor2);
                    text-transform: capitalize;
                    padding-left: 46px;
                    &:first-child {
                        padding-left: 0px;
                    }
                }
            }
        }
    }
}
