.exit-game-popup {
    width: 548px;
    height: 276px;
    position: absolute;
    top: -100px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    .background_popup {
        background: radial-gradient(ellipse at center, #97001f 40%, #6a0000 100%);
        height: 276px;
        .exit-game-content {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 45px;
            p {
                color: var(--mainColor2);
                font-size: 18px;
                width: 200px;
                text-align: center;
                line-height: 24px;
                text-transform: capitalize;
            }
        }
        .exit-game-button-block {
            display: flex;
            position: relative;
            top: 30px;
            right: 25%;

            .exit-first-button {
                width: 140px;
                height: 46px;
                position: absolute;
                right: 156px;
                .button-tree {
                    button {
                        background: #500000;
                        margin-right: 10px;
                        &:hover {
                            background: #602222;
                        }
                        span {
                            font-size: 15px;
                        }
                    }
                    .button-tree-under {
                        background: #400003;
                        bottom: -6px;
                    }
                }
            }
            .exit-second-button {
                width: 140px;
                height: 46px;
                position: absolute;
                right: 0;
                .button-tree {
                    button {
                        margin-right: 10px;
                        span {
                            font-size: 15px;
                        }
                    }
                    .button-tree-under {
                        bottom: -6px;
                    }
                }
            }
        }
    }
}
