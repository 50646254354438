.round-score-line {
    display: flex;
    justify-content: space-around;

    .round-score-one-line {
        .round-img {
            display: flex;
            span {
                color: var(--mainColor2);
                font-size: 14px;
                font-family: 'Roboto';
                font-weight: 700;
            }
        }
        .round-score-text {
            width: 192px;
            padding: 8px 0;
            background: rgba(2, 67, 13, 1);
            border-radius: 5px;
            text-align: center;
            margin-bottom: 2px;
            color: var(--mainColor2);
            font-size: 20px;
            font-family: 'Roboto';
            font-weight: 700;
            text-transform: uppercase;
        }
        .round-score-empty {
            width: 192px;
        }
        .round-score-item {
            width: 150px;
            padding: 5px 0;
            text-align: center;
            background: rgba(2, 67, 13, 1);
            border-radius: 5px;
            margin-bottom: 2px;
            color: var(--mainColor2);
            font-size: 18px;
            font-family: 'Roboto';
            font-weight: 700;
            text-transform: uppercase;
        }
    }
}
