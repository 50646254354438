.level-bar,
.level-bar-vip {
    height: 11px;
    min-width: 125px;
    box-sizing: border-box;
    border-radius: 7px;
    background: var(--mainColor17);
    .level-full,
    .level-full-vip {
        height: 100%;
        position: relative;
        transition: width 0.5s ease-in-out;
        border-radius: 6px;
        &.animating:after,
        &.animating-vip:after {
            position: absolute;
            right: -2px;
            top: 2;
            width: 3px;
            height: 130%;
            margin-top: -15%;
            background: var(--mainColor27);
        }
    }
}
.level-bar {
    border: 1px solid #430000;
}
.level-bar-vip {
    border: 1px solid var(--mainColor17);
}
.level-full {
    background: linear-gradient(#ffffff 0%, #a2a2a2 100%);
    top: 0.2px;
    left: -1px;
}
.level-full-vip {
    background: linear-gradient(#fade1e 0%, #ff9100 100%);
    top: 0.2px;
}
