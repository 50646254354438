.loading_bar {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    top: 48px;
    .loading_block {
        position: relative;
        width: 190px;
        margin: 0 5px;
        border-radius: 10px;
        box-sizing: border-box;
        margin: 0 auto;
        height: 10px;
        background: #610000;
        .loading_bar_fill {
            height: 8px;
            background: linear-gradient(#f0c99c 0%, #d89e58 100%);
            transition: width 0.3s ease-in-out;
            border-radius: 10px;
            position: absolute;
            top: 1px;
            left: 1px;
        }
    }
    span {
        font-size: 11px;
        color: var(--mainColor2);
        font-weight: 500;
        text-align: center;
        padding-top: 10px;
        letter-spacing: 0.5px;
    }
}
