.lobby-cashe-game {
    display: flex;
    justify-content: space-around;

    .lobby-cash-game-block {
        width: 240px;
        position: relative;
        cursor: pointer;
        .cash_game {
            cursor: pointer;
            transform: scale(0.8);
            width: 100%;
            transition: 0.4s ease-in-out;
            &:hover {
                transform: scale(1);
            }
            &.micro-game {
                margin-top: 120px;
            }
            &.big-game {
                margin-top: 110px;
            }
            &.prof-game {
                margin-top: 70px;
            }
            &.bonus-game {
                margin-top: 100px;
            }
        }
        .lock-table {
            opacity: 0.6;
        }

        .lock-img-bonus {
            position: absolute;
            right: 10%;
            width: 220px;
            top: 20%;
            height: 285px;

            &:hover {
                animation: lockTable 0.1s ease-in-out;
                animation-fill-mode: both;
                animation-iteration-count: 3;
            }
            img {
                position: absolute;
                right: 45px;
                top: 60px;
            }
        }

        .cash-game-score {
            width: 108px;
            height: 22px;
            background: linear-gradient(to right, #6a0000 0%, #ab0023 100%);
            margin: 0 auto;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            position: relative;
            top: -10px;

            &.cash-game-score-prof {
                width: 160px;
                background: #530004;
            }
            span {
                font-family: 'Roboto';
                text-align: center;
                color: var(--mainColor2);
                display: block;
                font-size: 13px;
                padding-top: 4px;
                font-weight: 700;
            }
            p {
                font-family: 'Roboto';
                text-align: center;
                color: var(--mainColor2);
                font-size: 13px;
                padding-top: 3px;
                text-transform: capitalize;
            }
        }
        .cash-game-score-bonus {
            width: 108px;
            height: 22px;
            background: linear-gradient(to right, #016b04 0%, #18b912 100%);
            margin: 0 auto;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            position: relative;
            top: -10px;
            &.cash-game-bonus {
                width: 160px;
            }
            span {
                font-family: 'Roboto';
                text-align: center;
                color: var(--mainColor2);
                display: block;
                font-size: 13px;
                padding-top: 2px;
            }
            p {
                font-family: 'Roboto';
                text-align: center;
                color: var(--mainColor2);
                font-size: 12px;
                padding: 2px;
                text-transform: capitalize;
            }
        }
    }
    .lobby_name {
        position: relative;
        top: -10px;
        .lobby_text {
            top: -40px;
            position: relative;
        }
    }
}
@keyframes lockTable {
    0% {
        position: absolute;
        right: 10%;
        width: 200px;
        top: 20%;
        height: 235px;
    }
    33% {
        position: absolute;
        right: 15%;
        width: 200px;
        top: 20%;
        height: 235px;
    }
    66% {
        position: absolute;
        right: 5%;
        width: 200px;
        top: 20%;
        height: 235px;
    }
    100% {
        position: absolute;
        right: 10%;
        width: 200px;
        top: 20%;
        height: 235px;
    }
}
