.yellow-tab {
    display: flex;
    justify-content: space-around;
    width: 100%;
    .yellow-tab-item {
        position: relative;
        width: 184px;
        height: 312px;

        .yellow-tab-bg {
            width: 100%;
            height: 100%;
            background-image: url(../../../assets/images/popups/store/store_bg.svg);
            background-repeat: no-repeat;
            position: relative;
            border-radius: 10px;
            .yellow-tab-chip {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                top: -25px;
                width: 65px;
            }
            .yellow-tab-chip-block {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 25px;
                img {
                    position: absolute;
                    bottom: 50px;
                }
                .yellow-tab-buy-chip {
                    display: flex;
                    .yellow-tab-buy-chip-block {
                        display: flex;
                        p {
                            color: var(--mainColor2);
                            font-size: 15px;
                        }
                    }
                }
                .yellow-tab-text {
                    font-size: 24px;
                    color: var(--mainColor2);
                    font-family: 'MardotoBlack';
                    padding-top: 10px;
                    text-transform: uppercase;
                    z-index: 1;
                }
                .yellow-tab-item-buy-now {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    padding: 0 10px;
                    width: 174px;
                    height: 42px;
                    border-radius: 6px;
                    background: linear-gradient(#fbdf4c 0%, #f6b72b 100%);
                    position: absolute;
                    bottom: 5px;
                    p,
                    span {
                        color: #5e0000;
                        font-size: 15px;
                        font-family: 'MardotoBlack';
                        text-transform: uppercase;
                        font-weight: 700;
                    }
                    span {
                        font-size: 19px;
                    }
                }
            }
            &:hover {
                box-shadow: 0px 0px 20px #ffe200;
            }
        }
    }
}
