.primary_ribbon {
    position: relative;
    height: 50px;

    .main_ribbon {
        height: 50px;
        padding: 0 50px;
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;

        &.gray {
            background: radial-gradient(#804f46 0%, #69443a 100%);
        }
        &.red {
            background: #cb2429;
        }
        &.yellow {
            background: radial-gradient(#f1b748 0%, #cb9236 100%);
        }
        span {
            white-space: nowrap;
            color: var(--mainColor2);
            font-size: 22px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .ribbon_tape {
        position: absolute;
        top: 13px;

        &.left_tape {
            left: -37px;
        }

        &.right_tape {
            right: -37px;
        }
    }
}
