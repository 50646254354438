.chip_info,
.chip_info_discount,
.chip-animate {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--mainColor17);
    border-radius: 18px;
    margin-left: 25px;
    height: 32px;
    margin-top: 12px;
    position: relative;
    cursor: pointer;
    .plus-icon {
        position: absolute;
        bottom: -5px;
        left: 18px;
    }
    img {
    }
    .chip {
        cursor: pointer;
        margin-left: 3px;
    }
    .chip_discount {
        margin-left: 0px;
        position: relative;
        top: 0px;
        left: 3px;
        width: 28px;
        height: 28px;
    }
    .amount,
    .amount_discount {
        color: var(--cColor);
        font-weight: 700;
        font-size: 16px;
        padding: 12px;
    }
    .amount_discount {
        padding: 4px;
        position: relative;
        left: 0px;
        padding: 4px;
    }
}
.chip_info_discount {
    z-index: 1;
    position: relative;
    top: -10px;
    width: 125px;
    img {
        position: absolute;
        left: -1px;
    }
}
.chip-animate {
    &:hover {
        .chip_discount {
            animation: animateChip 1s ease-in-out;
        }
    }
}
.chip-info-tour{
    background: #382919 0% 0% no-repeat padding-box;
}
@keyframes animateChip {
    0% {
        transform: translate3d(0px, 0px, 0px);
        animation-timing-function: linear;
    }
    11.1111% {
        transform: translate3d(0px, 0px, 0px);
        animation-timing-function: cubic-bezier(0, 0, 0.485, 0.985);
    }
    33.3333% {
        transform: translate3d(0px, -17px, 0px);
        animation-timing-function: cubic-bezier(0.555, 0.015, 1, 1);
    }
    55.5556% {
        transform: translate3d(0px, 0px, 0px);
        animation-timing-function: cubic-bezier(0, 0, 0.635, 0.995);
    }
    77.7778% {
        transform: translate3d(0px, -7px, 0px);
        animation-timing-function: cubic-bezier(0.395, 0.045, 1, 1);
    }
    100% {
        transform: translate3d(0px, 0px, 0px);
        animation-timing-function: ease-out;
    }
}
