.static-gift {
    position: absolute;
    width: 90px;
    height: 90px;

    img {
        width: 100%;
    }

    &.seat-1 {
        bottom: 90px;
        left: 50px;
    }

    &.seat-4 {
        bottom: 280px;
        left: 160px;
    }

    &.seat-3 {
        bottom: 440px;
        left: 640px;
    }
}

.seat-2 {
    top: 215px;
    left: 760px;
}
