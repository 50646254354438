.tournament-result-score {
    display: flex;

    .result-img-block {
        margin-top: 20px;
        position: relative;
        span {
            display: block;
            font-size: 100px;
            color: #4b3c28;
            font-family: 'Roboto-Slab';
            position: absolute;
            top: 15%;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .result-text-content {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        margin-left: 50px;
        .result-text-block {
            .result-score-text-block {
                span {
                    font-size: 15px;
                    color: var(--mainColor2);
                    font-family: 'Mardoto';
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
            .result-score-block {
                span {
                    font-size: 32px;
                    color: var(--mainColor2);
                    font-family: 'Mardoto';
                    font-weight: 700;
                }
            }
        }
        .result-chip-block {
            margin-top: 50px;

            .result-chip-img {
                margin-bottom: 10px;
                img {
                }
            }
            .result-chip-text {
                span {
                    font-size: 15px;
                    color: var(--mainColor2);
                    font-family: 'Mardoto';
                    font-weight: 500;
                    text-transform: uppercase;
                }
            }
            .result-chip-text-number {
                span {
                    font-size: 32px;
                    color: var(--mainColor2);
                    font-family: 'Mardoto';
                    font-weight: 700;
                }
            }
        }
    }
}
