.block-suit {
    display: flex;
    flex-direction: column;
    text-align: center;
    .suit-button {
        width: 63px;
        height: 63px;
        border-radius: 50%;
        border: 1px solid var(--mainColor1);
        display: flex;
        align-items: center;
        font-size: 36px;
        justify-content: center;
        background: var(--mainColor1);
        cursor: pointer;
        .suit-block {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background: transparent;
            position: relative;
            img {
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
        &:hover {
            background: #ffecb7;
        }
    }
    span {
        font-size: 12px;
        color: #7c614f;
        font-weight: 500;
        padding-top: 10px;
    }
}

.suit-button-selected {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    border: 1px solid var(--mainColor1);
    display: flex;
    align-items: center;
    font-size: 36px;
    justify-content: center;
    background: rgba(238, 201, 158, 0.19);
}
