.tournament-result-user-item {
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, 0.33);
    border-radius: 5px;
    height: 48px;
    margin-bottom: 10px;
    margin-left: 60px;
    width: 370px;
    .user-number-block,
    .user-number-block-3,
    .user-number-block-2,
    .user-number-block-1 {
        width: 72px;
        height: 22px;
        margin-left: 20px;
        border-radius: 3px;
        span {
            color: var(--mainColor2);
            font-size: 18px;
            font-family: 'MardotoBlack';
            display: block;
            text-align: center;
        }
    }
    .user-result-img-block {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-left: 10px;
        img {
            width: 100%;
        }
    }
    .user-result-name {
        padding-left: 10px;
        color: var(--mainColor2);
        font-size: 15px;
        font-family: 'Mardoto';
        font-weight: 500;
        width: 100px;
    }
    .tournament-result-user-score {
        display: flex;
        flex-direction: column;
        margin-left: 50px;
        .result-user-score {
            font-size: 18px;
        }
        .result-user-score-text {
            font-size: 12px;
            line-height: 10px;
            padding-top: 2px;
        }
        span {
            color: var(--mainColor2);
            font-family: 'Mardoto';
            display: block;
            text-align: left;
        }
    }
    .user-number-block {
        background: linear-gradient(180deg, #cbcbcb 0%, #919191 100%) 0% 0%;
    }
    .user-number-block-1 {
        background: linear-gradient(180deg, #fff08b 0%, #fc9000 100%);
    }
    .user-number-block-2 {
        background: linear-gradient(180deg, #cbcbcb 0%, #919191 100%) 0% 0%
            no-repeat padding-box;
    }
    .user-number-block-3 {
        background: linear-gradient(180deg, #ffa743 0%, #9e4d0f 100%) 0% 0%
            no-repeat padding-box;
    }
}
