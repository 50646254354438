.partisipation {
    position: absolute;
    left: 440px;
    top: 65px;
    height: 550px;
    width: 560px;
    .close-icon {
        position: absolute;
        top: -53px;
        right: 11px;
        cursor: pointer;
    }
    .tour-info-btn {
        display: flex;
        position: absolute;
        bottom: 30px;
        right: 40px;
        width: 300px;
        .tour-info-more {
            position: absolute;
            right: 150px;
            bottom: 10px;
            cursor: pointer;
            height: 46px;
            .button-tree {
                button {
                    background: #84745d;
                    span {
                        font-size: 15px;
                    }
                }
                .button-tree-under {
                    background: #3b281f;
                    bottom: -6px;
                }
            }
        }
        .tour-info-join {
            cursor: pointer;
            position: absolute;
            bottom: 10px;
            right: 0;
            height: 46px;
            .button-tree {
                button {
                    background: linear-gradient(
                        to right,
                        #3d9198 0%,
                        #1f5252 100%
                    );
                    span {
                        font-size: 15px;
                    }
                }
                .button-tree-under {
                    background: #323a32;
                    bottom: -6px;
                }
            }
        }
    }
}
