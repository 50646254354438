.user-info,
.user-info-game {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    display: flex;

    .user-info-left-side {
        position: absolute;
        left: -295px;
        width: 295px;
        height: 620px;
        box-shadow: 7px 0 14px #000000d4;
        background: linear-gradient(270deg, #97001f 0%, #6a0000 100%);
        z-index: 2;
        // animation: userInfoOpen 0.3s both;
        transition: left 0.3s ease-in-out;
        .main-user-info {
            width: 100%;
            height: 267px;
            position: relative;
            .edit-username {
                width: 128px;
                height: 30px;
                background: radial-gradient(#c1bcac 0%, #615e56 200%);
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-bottom-left-radius: 3px;
                border-bottom-right-radius: 3px;
                position: relative;
                margin-left: 150px;
                z-index: 1;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.6);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: transform 0.3s;
                &:hover {
                    height: 35px;
                }
                p {
                    color: var(--mainColor2);
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: 0.5px;
                    padding-left: 8px;
                }
                img {
                    margin-top: 5px;
                }
            }
            .main-user-info-back {
                width: 360px;
                position: absolute;
                left: -27px;
                top: -24px;
                opacity: 0.6;
            }
            .inner-wrapper {
                width: 100%;
                height: 267px;
                position: absolute;
                left: 0;
                top: 0;
                .user-pic-name {
                    width: 100%;
                    height: 166px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 15px;
                    box-sizing: border-box;
                    .user-img-wrapper {
                        height: 97px;
                        width: 97px;
                        position: relative;
                        border-radius: 50%;
                        background: #c1bcac;
                        img {
                            width: 97px;
                            height: 97px;
                            border-radius: 50%;
                            background: #c1bcac;
                        }
                        .level {
                            width: 30px;
                            height: 30px;
                            background: var(--mainColor2);
                            border-radius: 50%;
                            display: flex;
                            font-size: 13px;
                            align-items: center;
                            justify-content: center;
                            color: #010001;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                        }
                    }
                    .user-additional {
                        margin-left: 10px;
                        text-align: left;
                        width: calc(100% - 97px);
                        .user-name {
                            font-size: 16px;
                            font-weight: 700;
                            color: #ffffff;
                            text-shadow: 0 0 6px #00000026;
                        }
                        .user-id {
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 200;
                            color: #ffffff;
                            text-shadow: 0 0 6px #00000026;

                            .highlighted {
                                font-weight: bold;
                            }
                        }
                        .userinfo-level-bar {
                            margin-top: 10px;
                            background: #3c0007;
                            border: 3px solid #884149;
                            padding: 0 4px;
                            height: 16px;
                            width: 100%;
                            border-radius: 5px;
                            position: relative;
                            box-sizing: border-box;
                            .userinfo-level-full {
                                height: 100%;
                                position: relative;
                                transition: width 0.5s ease-in-out;
                                border-radius: 6px;
                                background: linear-gradient(
                                    #ffffff 0%,
                                    #a2a2a2 100%
                                );
                                top: 0.2px;
                                left: -5px;
                                &.animating:after,
                                &.animating-vip:after {
                                    position: absolute;
                                    right: -2px;
                                    top: 2;
                                    width: 3px;
                                    height: 130%;
                                    margin-top: -15%;
                                    background: #ecdec1;
                                    filter: blur(1px);
                                }
                            }
                        }
                    }
                }
                .connect-social {
                    width: 100%;
                    background: rgba(255, 255, 255, 0.2);
                    height: 50px;
                    display: flex;
                    justify-content: space-around;
                    span {
                        color: var(--mainColor2);
                        font-family: 'Mardoto';
                        font-size: 16px;
                        display: block;
                        width: 115px;
                        padding-left: 5px;
                        padding-top: 5px;
                    }
                    .social-img {
                        cursor: pointer;
                    }
                }
            }
        }
        .games-played-content {
            width: 100%;
            height: 222px;
            display: flex;
            align-items: center;
            justify-content: center;

            .games-played {
                display: flex;
                flex-direction: column;
                position: relative;

                .games-played-text {
                    text-align: center;
                    padding-left: 30px;
                    color: var(--mainColor2);
                    font-family: 'Roboto';
                    font-weight: bold;
                    font-size: 16px;
                }

                .games-played-wrapper {
                    position: relative;
                    height: 78px;

                    img {
                        position: absolute;
                        left: 0px;
                        width: 80px;
                    }

                    .games-played-text-wrapper {
                        width: 200px;
                        margin-left: 30px;
                        height: 30px;
                        margin-top: 22px;
                        border-radius: 5px;
                        border: 1px dashed #ffffff26;
                        background: rgb(73, 0, 16);
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;

                        span {
                            color: var(--mainColor2);
                            font-family: 'Roboto';
                            font-weight: bold;
                            margin-right: 50px;
                        }
                    }
                }
            }
        }
        .win-rate {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            .win-rate-info {
                display: flex;
                flex-direction: column;
                .rate-text {
                    color: var(--mainColor2);
                    font-size: 18px;
                    font-family: 'Roboto';
                    font-weight: bold;
                    width: 100px;
                    padding-bottom: 10px;
                }
                .secondary-label {
                    font-weight: 200;
                    color: var(--mainColor2);
                    width: 120px;
                }
            }
        }
    }
    .empty-close {
        position: absolute;
        width: 705px;
        height: 620px;
        left: 295px;
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        // animation: userInfoClose 0.5s both;
    }
    &.open {
        .user-info-left-side {
            left: 0;
        }
    }
}
@keyframes userInfoOpen {
    0% {
        left: -295px;
    }
    100% {
        left: 0px;
    }
}
@keyframes userInfoClose {
    0% {
        left: -15px;
    }
    100% {
        left: -315px;
        width: 0px;
    }
}

.user-info-game {
    background: rgba(0, 0, 0, 0.3);
    .empty-close {
        left: 0;
        width: 1000px;
        background: rgba(0, 0, 0, 0.3);
    }
}
