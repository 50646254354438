.tournament-rewards {
    position: absolute;
    left: 410px;
    height: 615px;
    width: 590px;
    .tour-rewards-lobby {
        .back_lobby {
            width: 140px;
            top: 50px;
        }
    }

    .tournament-rewards-content {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 550px;
        margin: 0 auto;
        margin-top: 100px;
        max-height: 380px;
    }
    .tour-info-btn {
        display: flex;
        position: absolute;
        bottom: 30px;
        right: 40px;
        width: 300px;
        .tour-info-more {
            position: absolute;
            right: 150px;
            bottom: 10px;
            height: 46px;
            cursor: pointer;
            .button-tree {
                button {
                    background: #84745d;
                    span {
                        font-size: 15px;
                    }
                }
                .button-tree-under {
                    background: #3b281f;
                    bottom: -6px;
                }
            }
        }
        .tour-info-join {
            cursor: pointer;
            position: absolute;
            bottom: 10px;
            right: 0;
            height: 46px;
            .button-tree {
                button {
                    background: linear-gradient(
                        to right,
                        #3d9198 0%,
                        #1f5252 100%
                    );
                    span {
                        font-size: 15px;
                    }
                }
                .button-tree-under {
                    background: #323a32;
                    bottom: -6px;
                }
            }
        }
    }
}
