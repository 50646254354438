.lobby_name {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .lobby_text {
    text-transform: uppercase;
    font-size: 19px;
    font-weight: 700;
    color: var(--mainColor2);
  }
  img {
    margin-bottom: 10px;
  }
}
