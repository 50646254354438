.menu_item,
.menu_item_active {
    display: flex;
    align-items: center;
    height: 40px;
    background: rgba(255, 255, 255, 0.13);

    width: 282px;
    .menu_item_block {
        display: flex;
        align-items: center;
        margin-left: 28px;
        width: 100px;
        .menu_item_text,
        .menu_item_text_active {
            text-transform: capitalize;
            font-weight: 500;
            font-size: 16px;
            padding-left: 10px;
            color: var(--mainColor2);
        }
    }
    .toggle {
        .toggle_in {
            width: 34px;
            height: 16px;
            border-radius: 15px;
            margin-left: 35px;
            background-color: var(--mainColor2);
            border: 1px solid var(--mainColor22);
            position: relative;
            transition: background-color 0.3s ease-in-out;
            .toggle_active {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: linear-gradient(#144c0c 0%, #289718 100%);
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.43);
                position: absolute;
                top: 1px;
                left: 2px;
                transition: left 0.3s ease-in-out;
                &.icon {
                    left: 18px;
                }
            }
        }
    }
    &:hover {
        background: rgba(255, 255, 255, 0.2);
    }
}

.menu_item_active {
    background: rgba(255, 255, 255, 0.36);
    border-radius: 24px;
    margin-bottom: 10px;
    margin-bottom: 20px;
    .menu_item_block {
        .menu_item_text_active {
            color: var(--mainColor2);
        }
    }
    .toggle {
        margin-left: 70px;
        .toggle_in {
            .toggle_active {
                background: linear-gradient((#289718 0%, #144c0c 100%));
                left: 18px;
            }
        }
    }
    &:hover {
        background: rgba(255, 255, 255, 0.36);
    }
}
