.user-panel,
.user-panel-tournament {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background: linear-gradient(to right, #4b0000 0%, #740017 100%);
    box-shadow: 0 10px 12px rgba(0, 0, 0, 0.16);
    width: 1000px;
    margin: 0 auto;
    position: absolute;
    z-index: 2;

    .left_side {
        display: flex;
        margin-left: 14px;
        width: 680px;

        .chips_wrapper {
            display: flex;
            width: 100%;
            padding: 0 12px;
        }
        .user_data {
            margin-left: 20px;
            margin-top: 5px;
            .user_name_points {
                display: flex;
                justify-content: space-between;
                margin-bottom: 3px;

                .nickname {
                    font-size: 16px;
                    color: var(--mainColor2);
                    font-weight: 700;
                }
                .nickname-vip {
                    color: var(--mainColor27);
                    font-size: 16px;
                    font-weight: 700;
                }
            }
            .user_edge {
                font-size: 10px;
                font-weight: 500;
                color: var(--mainColor2);
                padding-top: 3px;
                padding-left: 2px;
            }
        }
    }
    .right_side {
        display: flex;
        .icon_button {
            margin: 0 5px;
            width: 50px;
            img {
                width: 27px;
                height: 27px;
            }
        }
    }
    .user_panel_store {
        position: absolute;
        right: 100px;
        top: 5px;
        cursor: pointer;
        &:hover {
            animation: storeAnimate 1s ease-in-out;
        }

        // animation-name: scaleStore;
        // animation-duration: 2s;
        // animation-iteration-count: infinite;
    }
    .user_panel_whell {
        position: absolute;
        right: 100px;
        top: 5px;
        cursor: pointer;
        animation-name: scaleWhell;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    .burger-menu {
        z-index: 1;
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: relative;
        right: 30px;
        top: 5px;
        img {
            width: 100%;
            position: relative;
            top: -5px;
            &:hover {
                animation: rotation 0.5s steps(360);
            }
        }
    }
    .burger-menu-hover {
        padding-right: 25px;
        .burger-menu-item-first,
        .burger-menu-item-second,
        .burger-menu-item-third {
            display: block;
            height: 4px;
            background-color: var(--mainColor2);
            width: 20px;
            margin-bottom: 4px;
            border-radius: 2px;
        }
        &:hover {
            .burger-menu-item-first {
                position: relative;

                top: -3px;
            }
            .burger-menu-item-third {
                position: relative;

                top: 3px;
            }
        }
    }
    .drop-down-menu {
        animation-name: dropDownMenu;
        animation-fill-mode: both;
        animation-duration: 1s;
        width: 213px;
        height: 250px;
        position: absolute;
        right: 15px;
        top: 15px;
    }
}
.user-panel-tournament {
    background: linear-gradient(to right, #3b2d1b 0%, #b59a74 100%);
    .left_side{
        .user_data{
            .level-bar{
                background: #2C251C;
                border: #2C251C;
            }
        }
    }
}
@keyframes dropDownMenu {
    0% {
        height: 0px;
    }
    100% {
        height: 150px;
    }
}

@keyframes scaleStore {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes scaleWhell {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes storeAnimate {
    0% {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        animation-timing-function: linear;
    }
    11.1111% {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        animation-timing-function: linear;
    }
    33.3333% {
        transform: translate3d(0px, 3px, 0px) scale3d(1, 0.892157, 1);
        animation-timing-function: linear;
    }
    55.5556% {
        transform: translate3d(0px, -2px, 0px) scale3d(1, 1.08333, 1);
        animation-timing-function: linear;
    }
    77.7778% {
        transform: translate3d(0px, 2px, 0px) scale3d(1, 0.933822, 1);
        animation-timing-function: linear;
    }
    100% {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1.00613, 1);
        animation-timing-function: linear;
    }
}
@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(180deg);
    }
}
