.user-login {
    position: relative;
    width: 1000px;
    // background-image: url('../../assets/images/splash-screen/user-login-bg.svg');
    height: 620px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .background {
        .background_block {
            background: transparent;
        }
        .user-login-content {
            display: flex;

            .left-user-login {
                display: flex;
                background: linear-gradient(to right, #6a0000 0%, #ab0023 100%);
                position: relative;
                width: 40%;
                height: 620px;
                z-index: 999;
                justify-content: center;
                flex-direction: column;
                box-shadow: 12px 0px 12px rgba(0, 0, 0, 0.16);
                .login-text {
                    font-size: 24px;
                    color: #fff;
                    padding-bottom: 36px;
                    font-weight: bold;
                    padding-left: 48px;
                    padding-top: 360px;
                }
                .social-btns {
                    padding-top: 36px;
                    .play-game {
                        display: flex;
                        width: 80%;
                        margin: 0 auto;
                        height: 54px;
                        font-size: 14px;
                        color: #fff;
                        font-weight: bold;
                        text-transform: uppercase;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 30px;
                        border-radius: 10px;
                        position: absolute;
                        bottom: 50px;
                        left: 40px;
                        cursor: pointer;
                        img {
                            height: 30px;
                        }
                        &.fb {
                            background-color: #00498d;
                            box-shadow: 1px 10px 0px 0px #002a51;
                            -webkit-box-shadow: 1px 10px 0px 0px #002a51;
                            -moz-box-shadow: 1px 10px 0px 0px #002a51;
                        }
                        &.ok {
                            background-color: #ff8800;
                            box-shadow: 1px 10px 0px 0px #742f00;
                            -webkit-box-shadow: 1px 10px 0px 0px #742f00;
                            -moz-box-shadow: 1px 10px 0px 0px #742f00;
                        }
                        &.vk {
                            background-color: #4d668a;
                            box-shadow: 1px 10px 0px 0px #314158;
                            -webkit-box-shadow: 1px 10px 0px 0px #314158;
                            -moz-box-shadow: 1px 10px 0px 0px #314158;
                        }
                        &:not(:last-of-type) {
                            margin-bottom: 25px;
                        }
                    }
                }
            }
            .right-user-login {
                width: 60%;
                padding: 90px 105px;
                display: flex;
                flex-direction: column;

                align-items: center;
                .right-blot-logo {
                    max-width: 430px;
                    width: 100%;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
                .or {
                    font-size: 30px;
                    color: #fff;
                    font-weight: bold;
                    margin-top: 140px;
                }
                .play-game {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    bottom: 50px;
                    margin-top: 40px;
                    height: 54px;
                    font-size: 18px;
                    color: #fff;
                    font-weight: bold;
                    text-transform: uppercase;
                    padding: 0 100px;
                    border-radius: 10px;
                    background: rgb(11, 172, 22);
                    background: linear-gradient(
                        90deg,
                        rgba(11, 172, 22, 1) 0%,
                        rgba(0, 90, 6, 1) 100%
                    );
                    box-shadow: 1px 10px 0px 0px #2b2e03;
                    -webkit-box-shadow: 1px 10px 0px 0px #2b2e03;
                    -moz-box-shadow: 1px 10px 0px 0px #2b2e03;
                    cursor: pointer;
                }
            }
        }
    }
}

// @media (max-width: 1920px) {
//     .user-login {
//         .background {
//             .user-login-content {
//                 .left-user-login {
//                     padding: 90px 45px;
//                     .social-btns {
//                         .login-btn {
//                             font-size: 24px;
//                             padding: 0 24px;
//                             height: 72px;
//                             img {
//                                 height: 32px;
//                             }
//                         }
//                     }
//                 }
//                 .right-user-login {
//                     padding: 90px 45px;
//                     .play-game {
//                         height: 72px;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-height: 860px) {
//     .user-login {
//         .background {
//             .user-login-content {
//                 .right-user-login {
//                     .right-blot-logo {
//                         margin-bottom: 60px;
//                     }
//                     .or {
//                         margin-bottom: 80px;
//                     }
//                 }
//             }
//         }
//     }
// }
// @media (max-width: 1365px) {
//     .user-login {
//         .background {
//             .user-login-content {
//                 .left-user-login {
//                     width: 440px;
//                     padding: 70px 30px;
//                     .social-btns {
//                         .login-btn {
//                             font-size: 20px;
//                             &:not(:last-of-type) {
//                                 margin-bottom: 30px;
//                             }
//                         }
//                     }
//                 }
//                 .right-user-login {
//                     width: calc(100% - 440px);
//                     padding: 70px 30px;
//                     .right-blot-logo {
//                         width: 320px;
//                         margin-bottom: 30px;
//                     }
//                     .or {
//                         margin-bottom: 30px;
//                     }
//                 }
//             }
//         }
//     }
// }
