.tooltip {
    .tooltip-suit {
        position: absolute;
        top: -50px;
        width: 110px;
        height: 40px;
        background: var(--mainColor2);
        border-radius: 10px;
        z-index: 1;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 33px;
            transform: rotate(48deg);
            left: 10px;
            border-top: 6px solid transparent;
            border-right: 11px solid var(--mainColor2);
            border-bottom: 10px solid transparent;
        }
        img {
            width: 28px;
        }
        .pass-say {
            position: absolute;
            top: 14%;
            left: 25%;
            right: 0;
            bottom: 0;
            margin: auto;
            font-size: 24px;
            font-weight: 700;
            color: var(--mainColor26);
        }
        .item-text {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            font-size: 24px;
            font-weight: 700;
            text-transform: capitalize;
            color: var(--mainColor26);
        }

        .pass-say-value {
            top: 17%;
            position: absolute;
            left: 13%;
            right: 0;
            bottom: 0;
            margin: auto;
            font-size: 24px;
            font-weight: 700;
            text-transform: capitalize;
            color: var(--mainColor26);
        }
        .say-suit {
            position: absolute;
            top: -4px;
            left: 110px;
            font-size: 24px;
            font-weight: 700;
            color: var(--mainColor26);
        }

        .suit-img {
            position: absolute;
            right: 146px;
            top: 38px;
            width: 24px;
        }
    }
}

.tooltip-3 {
    .tooltip-suit {
        right: 130px;
        top: 95px;
        z-index: 1;
        animation: tooltipUser 0.7s both;
        transform-origin: top right;

        &::before {
            content: '';
            left: 90px;
            top: -10px;
            border-left: 12px solid var(--mainColor2);
            border-bottom: 10px solid transparent;
            border-top: 6px solid transparent;
            border-right: none;
            transform: rotate(180deg);
        }
        .say-suit {
            left: 60px;
            top: 4px;
        }
        .suit-img {
            right: 60px;
            top: 7px;
        }
    }
}
.tooltip-1 {
    .tooltip-suit {
        z-index: 1;
        top: -40px;
        left: 80px;
        animation: myMove 0.7s both;
        transform-origin: bottom left;
        .say-suit {
            left: 60px;
            top: 5px;
        }
        .suit-img {
            left: 24px;
            top: 7px;
        }
    }
}

.tooltip-2 {
    .tooltip-suit {
        top: -50px;
        right: 70px;
        animation: tooltipLastUser 0.7s both;
        transform-origin: bottom right;
        animation-timing-function: ease-in-out;
        opacity: 1;
        &::before {
            content: '';
            left: 85px;
            top: 35px;
            border-left: 18px solid var(--mainColor2);
            border-bottom: 10px solid transparent;
            border-top: 6px solid transparent;
            border-right: none;
            transform: rotate(80deg);
        }
        .say-suit {
            left: 57px;
            top: 5px;
        }
        .suit-img {
            right: 60px;
            top: 7px;
        }
    }
}
.tooltip-4 {
    .tooltip-suit {
        z-index: 1;
        top: -50px;
        left: 70px;
        animation: tooltipSecondUser 0.7s both;
        transform-origin: bottom left;
        animation-timing-function: ease-in-out;
        .say-suit {
            left: 60px;
            top: 5px;
        }
        .suit-img {
            right: 60px;
            top: 7px;
        }
    }
}
@keyframes tooltipSecondUser {
    0% {
        transform: rotate(90deg);
    }

    70% {
        transform: rotate(-2deg);
        opacity: 1;
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}
@keyframes myMove {
    0% {
        transform: rotate(90deg);
    }
    70% {
        transform: rotate(-2deg);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}
@keyframes tooltipUser {
    0% {
        z-index: 1;
        transform: rotate(90deg);
    }
    70% {
        transform: rotate(-2deg);
        opacity: 1;
    }
    100% {
        transform: rotate(0deg);
        opacity: 1;
        z-index: 1;
    }
}
@keyframes tooltipLastUser {
    0% {
        transform: rotate(-90deg);
    }

    70% {
        opacity: 1;
        transform: rotate(2deg);
    }
    100% {
        z-index: 1;
        transform: rotate(0deg);
        opacity: 1;
    }
}
