.splash-screen {
    width: 100%;
    position: relative;
    .container {
        width: 1700px;
        margin: 0 auto;
        padding: 0 15px;
        box-sizing: border-box;
        .splash-screen-block {
            display: flex;
            justify-content: center;
            padding-top: 80px;
            .left-block {
                width: 30%;
                margin: 0 auto;
                .left-first-card {
                    width: 170px;
                    margin: 0 auto;
                    margin-bottom: 40px;
                    img {
                        width: 100%;
                    }
                }
                .left-media-card {
                    display: none;
                }
                .left-second-card {
                    margin-left: -50px;
                    text-align: left;
                    width: 100px;
                    margin-bottom: 100px;
                    img {
                        width: 100%;
                    }
                }
                .left-third-card {
                    width: 140px;
                    margin: 0 auto;
                    img {
                        width: 100%;
                    }
                }
            }
            .center-block {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 0 70px;
                .splash-candy-logo-center {
                    display: none;
                }
                .welcome-text {
                    display: block;
                    color: var(--mainColor2);
                    font-size: 39px;
                    font-family: 'Designosaur';
                    margin-bottom: 110px;
                }
                .candy-wings-logo {
                    margin-bottom: 95px;
                }
                .center-card-block {
                    display: none;
                }
                .center-button-block,
                .center-button-block-mobile {
                    background: linear-gradient(
                        to right,
                        #0bac16 0%,
                        #005a06 100%
                    );
                    border-radius: 50px;
                    margin-bottom: 100px;
                    box-shadow: 0px 7px 2px #012904;
                    width: 200px;
                    height: 42px;
                    cursor: pointer;
                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        text-transform: uppercase;
                        color: var(--mainColor2);
                        font-family: 'Designosaur';
                        padding-top: 4px;
                        letter-spacing: 2px;
                    }
                    &:hover {
                        background: #0bac16;
                    }
                }
                .center-button-block-mobile {
                    display: none;
                    justify-content: center;
                    align-items: center;
                }
                .app-play-market {
                    display: flex;
                    width: 50%;
                    justify-content: space-around;
                    margin-bottom: 100px;

                    .app-store {
                        img {
                            width: 100%;
                        }
                        &:hover {
                            animation: appStore 0.5s ease-in-out;
                            animation-fill-mode: both;
                        }
                    }
                    .play-market {
                        margin-left: 10px;
                        img {
                            width: 100%;
                        }
                        &:hover {
                            animation: appStore 0.5s ease-in-out;
                            animation-fill-mode: both;
                        }
                    }
                }
            }
            .right-block {
                display: flex;
                flex-direction: column;
                width: 30%;

                .right-question-icon {
                    text-align: right;
                    margin-bottom: 70px;
                }
                .right-card-block {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    margin-bottom: 150px;
                    .right-media-first-card {
                        display: none;
                    }
                    .right-media-second-card {
                        display: none;
                    }
                    .right-first-card {
                        margin-right: 30px;
                        margin-bottom: 200px;
                        width: 150px;
                        img {
                            width: 100%;
                        }
                    }
                    .right-second-card {
                        margin-bottom: 40px;
                        width: 120px;
                        img {
                            width: 100%;
                        }
                    }
                }

                .right-bottom-block {
                    margin-top: 50px;
                    display: flex;
                    margin-bottom: 40px;

                    span {
                        display: block;
                        font-size: 25px;
                        color: rgba(255, 255, 255, 0.5);
                        font-family: 'Designosaur';
                    }
                    .splash-candy-logo {
                        padding-left: 30px;
                        width: 200px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .coins-chips {
        max-width: 650px;
        position: absolute;
        bottom: 0;

        left: 0;
        img {
            width: 100%;
        }
    }
    a {
        text-decoration: none;
    }
}

@media (max-width: 1700px) {
    .splash-screen {
        .container {
            width: 1500px;
            .splash-screen-block {
                .left-block {
                    .left-second-card {
                        margin-left: -10px;
                    }
                }
            }
        }
    }
}
@media (max-width: 1500px) {
    .splash-screen {
        .container {
            width: 1300px;
            .splash-screen-block {
                .left-block {
                    .left-second-card {
                        margin-left: 0px;
                    }
                }
                .center-block {
                    .app-play-market {
                        .play-market {
                            margin-left: 10px;
                        }
                    }
                    .candy-wings-logo {
                        width: 400px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .right-block {
                    .right-card-block {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                    }
                    .right-bottom-block {
                        span {
                            font-size: 22px;
                        }
                    }
                }
            }
        }
        .coins-chips {
            max-width: 450px;
        }
    }
}
@media (max-width: 1300px) {
    .splash-screen {
        .container {
            width: 1100px;
            .splash-screen-block {
                justify-content: space-evenly;
                .left-block {
                    .left-first-card {
                        margin-bottom: 50px;
                        img {
                            width: 100%;
                        }
                    }
                    .left-second-card {
                        margin-bottom: 100px;
                    }
                }
                .right-block {
                    .right-question-icon {
                        text-align: center;
                    }
                    .right-bottom-block {
                        .splash-candy-logo {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
    .coins-chips {
        width: 300px;
    }
}
@media (max-width: 1250px) {
    .splash-screen {
        .container {
            .right-block {
                .right-question-icon {
                    text-align: right;
                    margin-right: 40px;
                }
            }
        }
    }
    .coins-chips {
        width: 300px;
    }
}
@media (max-width: 1250px) {
    .splash-screen {
        .container {
            .splash-screen-block {
                justify-content: center;
                .center-block {
                    margin: 0 80px;
                }
                .right-block {
                    .right-question-icon {
                        text-align: right;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
    .coins-chips {
        width: 300px;
    }
}
@media (max-width: 1150px) {
    .splash-screen {
        .container {
            .splash-screen-block {
                justify-content: center;
                .center-block {
                    margin: 0px;
                }
                .right-block {
                    .right-question-icon {
                        text-align: center;
                        margin-right: 40px;
                    }
                    .right-bottom-block {
                        span {
                            display: none;
                        }
                        .splash-candy-logo {
                            width: 200px;
                        }
                    }
                }
            }
        }
    }
    .coins-chips {
        width: 300px;
    }
}
@media (max-width: 1000px) {
    .splash-screen {
        .container {
            width: 900px;
            .splash-screen-block {
                .left-block {
                    .left-media-card {
                        display: block;
                    }
                    .left-first-card {
                        display: none;
                    }
                    .left-second-card {
                        display: none;
                    }
                    .left-third-card {
                        display: none;
                    }
                }
                .center-block {
                    .welcome-text {
                        font-size: 31px;
                    }
                    .center-button-block {
                        display: none;
                    }
                    .center-button-block-mobile {
                        display: flex;
                    }
                }
                .right-block {
                    .right-question-icon {
                        display: none;
                    }
                    .right-card-block {
                        .right-media-first-card {
                            display: block;
                            margin-bottom: 100px;
                        }
                        .right-media-second-card {
                            display: block;
                        }
                        .right-first-card {
                            display: none;
                        }
                        .right-second-card {
                            display: none;
                        }
                    }
                    .right-bottom-block {
                        margin: 0;
                        .splash-candy-logo {
                            position: absolute;
                            bottom: 50px;
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 870px) {
    .splash-screen {
        .container {
            width: 700px;
            .splash-screen-block {
                .left-block {
                    .left-media-card {
                        width: 150px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .center-block {
                    .candy-wings-logo {
                        margin-bottom: 0px;
                    }
                    .center-button-block-mobile {
                        margin-bottom: 20px;
                    }
                    .center-card-block {
                        display: block;
                        width: 80px;
                        margin-left: 400px;
                        margin-bottom: 20px;
                        img {
                            width: 100%;
                        }
                    }
                    .app-play-market {
                        display: flex;

                        width: 100%;

                        .app-store {
                            margin-bottom: 10px;
                        }
                        .play-market {
                            margin-left: 10px;
                        }
                    }
                }
                .right-block {
                    .right-media-first-card {
                        width: 150px;
                        img {
                            width: 100%;
                        }
                    }
                    .right-card-block {
                        .right-media-second-card {
                            display: none;
                            width: 150px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .coins-chips {
            width: 300px;
        }
    }
}
@media (max-width: 700px) {
    .splash-screen {
        .container {
            width: 600px;
            .splash-screen-block {
                .right-block {
                    .right-bottom-block {
                        .splash-candy-logo {
                            right: 30px;
                        }
                    }
                }

                .center-block {
                    height: auto;
                    .welcome-text {
                        font-size: 26px;
                        letter-spacing: 1px;
                        margin-bottom: 60px;
                        margin-top: 60px;
                    }
                    .candy-wings-logo {
                        width: 250px;
                    }

                    .app-play-market {
                        margin-bottom: 100px;
                        .app-store {
                            img {
                                width: 100%;
                            }
                        }
                        .play-market {
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .splash-candy-logo-center {
                        margin-top: 20px;
                        width: 150px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .coins-chips {
            width: 300px;
        }
    }
}
@media (max-width: 570px) {
    .splash-screen {
        .container {
            width: 500px;
            .splash-screen-block {
                .left-block {
                    .left-media-card {
                        width: 80px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .center-block {
                    .center-button-block-mobile {
                        margin-bottom: 50px;
                        box-shadow: 0px 4px 2px #012904;
                        a {
                            font-size: 14px;
                        }
                    }
                    .center-card-block {
                        margin-left: 250px;
                    }
                }
                .right-block {
                    .right-bottom-block {
                        .splash-candy-logo {
                            bottom: 20px;
                            right: 50px;
                        }
                    }
                    .right-card-block {
                        .right-media-first-card {
                            width: 50px;
                        }
                    }
                }
            }
        }
        .coins-chips {
            width: 200px;
        }
    }
}

@media (max-width: 450px) {
    .splash-screen {
        .container {
            width: 400px;
            .splash-screen-block {
                .center-block {
                    .welcome-text {
                        font-size: 16px;
                    }
                    .candy-wings-logo {
                        width: 200px;
                    }
                    .center-button-block-mobile {
                        width: 150px;
                        height: 30px;
                        a {
                            display: block;
                            font-size: 12px;
                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                letter-spacing: 0px;
                            }
                        }
                    }
                    .center-card-block {
                        margin-left: 200px;
                    }
                    .app-play-market {
                        width: 250px;
                    }
                }
                .right-block {
                    .right-bottom-block {
                        .splash-candy-logo {
                            width: 150px;
                            right: 30px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 350px) {
    .splash-screen {
        .container {
            width: 320px;
        }
        .coins-chips {
            width: 160px;
        }
    }
}
@keyframes appStore {
    0% {
        transform: scale(1);
    }
    30% {
        transform: scale(1.02);
    }
    60% {
        transform: scale(1.05);
    }
    75% {
        transform: scale(1.07);
    }
    100% {
        transform: scale(1.1);
    }
}
