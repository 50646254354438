.say-button {
    cursor: pointer;
    z-index: 1;

    .in-active {
        background: rgba(238, 201, 158, 0.2);
        span {
            font-size: 20px;
            font-family: 'Roboto';
            font-weight: 700;
            color: #000;
        }
    }
    span {
        font-size: 20px;
        font-family: 'Roboto';
        font-weight: 700;
        color: #000;
    }
}
