.game-end {
    width: 888px;
    height: 446px;
    border: 1px solid var(--mainColor16);
    background: var(--mainColor15);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 10px;
    z-index: 10;
    .game-end-ribbon {
        display: flex;
        justify-content: center;
        margin-top: -40px;
        margin-bottom: -10px;
    }
    .best-score-block {
        height: 140px;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 140px;
        overflow: hidden;
    }
    .star-block {
        display: flex;
        justify-content: space-between;
        width: 250px;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .final-score {
        text-align: center;
        margin-bottom: 20px;
        margin-top: 30px;
        .game-end-force-end {
            padding-bottom: 30px;
            padding-top: 30px;
            color: #fff;
            font-size: 24px;
            text-transform: uppercase;
        }
        .final-score-text {
            font-size: 15px;
            font-family: 'Roboto';
            font-weight: 300;
            color: var(--mainColor2);
            margin-bottom: 10px;
            display: block;
        }
        .score {
            display: flex;
            justify-content: center;
            font-size: 26px;
            font-family: 'Roboto';
            font-weight: 700;
            color: var(--mainColor2);
            padding-bottom: 30px;
            .score-us {
                opacity: 0.5;
            }
            .score-square {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                margin: 0 15px;
                span {
                    border: 3px solid var(--mainColor2);
                    opacity: 18%;
                    height: 6px;
                    background: var(--mainColor2);
                    display: block;
                }
            }
        }
    }
    .game-end-rewards {
        display: block;
        text-align: center;
        font-size: 15px;
        color: var(--mainColor2);
        font-weight: 300;
        text-transform: uppercase;
        padding-bottom: 10px;
    }
    .game-end-chip-block {
        background: var(--mainColor16);
        width: 756px;
        height: 100px;
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        margin: 0 auto;
        margin-bottom: 20px;
    }
    .game-end-button {
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;

        .button-tree {
            height: 46px;
            button {
                width: 180px;
                background: radial-gradient(#cb9236 0%, #f1b748 100%);
            }
            span {
                color: var(--mainColor2);
                font-size: 15px;
                font-weight: 700;
                text-transform: uppercase;
            }
            .button-tree-under {
                background: #cb9236;
                bottom: -5px;
                width: 180px;
            }
        }
    }
    .leave-game {
        display: block;
        text-align: center;
        cursor: pointer;
        font-size: 13px;
        font-weight: 500;
        color: var(--mainColor2);
        text-transform: uppercase;
        padding-top: 75px;
        width: 100px;
        margin: 0 auto;
    }
}
