.spector-chart {
    position: absolute;
    width: 100px;
    height: 100px;
    bottom: -5px;
    left: -6px;
    #id {
        position: absolute;
    }
    img {
        position: absolute;
        width: 70px;
        height: 70px;
    }
}
