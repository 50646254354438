.user_avatar_name {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 13px;
    width: 120px;
    padding: 2px 0;
    margin-top: 10px;
    img {
        position: absolute;
        bottom: 38px;
        left: 20px;

        height: 80px;
    }
    .game_user_name {
        display: flex;
        font-family: 'Roboto';
        font-weight: 700;
        //padding-top: 3px;
        justify-content: center;
        align-items: center;
        color: var(--cColor);
    }
}
