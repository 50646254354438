.user_score_1,
.user_score_2 {
    position: absolute;
    border-radius: 5px;
    background: var(--mainColor4);
    span {
        display: block;
        text-align: center;
        line-height: 26px;
        color: var(--mainColor6);
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 14px;
        padding: 0 7px;
    }
}
.user_score_1 {
    left: 625px;
    bottom: 0px;
}
.user_score_2 {
    right: 50px;
    bottom: -80px;
}
