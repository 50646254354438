.user-gift-2,
.user-gift-3,
.user-gift-4 {
    position: absolute;
    cursor: pointer;
}
.user-gift-2 {
    top: 95px;
    right: 125px;
}
.user-gift-3 {
    top: 94px;
    left: 135px;
}
.user-gift-4 {
    left: 135px;
    top: 94px;
}
