$loading_height: 620px;
#language-page {
  position: relative;
  background: radial-gradient(ellipse at center, #97001f 0, #6a0000 100%);
  .language_wrapper {
    position: absolute;
    left: 0;
    top: 175px;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 450px;
    height: 310px;
    .logo_language {
      display: block;
      margin: 0 auto;
      margin-bottom: 112px;
    }
    .language_block {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      position: relative;
      top: 30px;
      .language_hy,
      .language_ru,
      .language_en {
        width: 103px;
        height: 42px;
        border-radius: 5px;
        background: #500000;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        color: var(--mainColor2);
        font-size: 20px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        cursor: pointer;
        z-index: 1;
      }
    }
  }
  .language_bg {
    border: 1px solid rgba(255, 145, 145, 0.1);
    width: 1000px;
    position: absolute;
    bottom: 100px;
  }
  .language_bg_border {
    border: 1px solid #610000;
    width: 1000px;
    position: absolute;
    bottom: 101px;
  }
}
