.waiting_loader_img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0%;
    right: 0;
    animation: circle 2s steps(360)  infinite;
}

@keyframes circle {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(359deg);
    }
}