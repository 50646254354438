.lobby_content {
    overflow: hidden;
    width: 1000px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    .lobby_item {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        height: 100%;
        .lobby_menu {
            position: relative;
            width: 1000px;
            .lobby_games {
                display: flex;
                justify-content: space-around;
                align-items: center;
                height: 580px;
                .lobby_game {
                    width: 300px;
                    cursor: pointer;
                    .cash_game {
                        width: 100%;
                        transform: scale(1);
                        transition: 0.4s ease-in-out;
                        &:hover {
                            transform: scale(1.2);
                        }
                    }
                    .yellow_rectangle {
                        margin-bottom: 10px;
                    }
                    span {
                        text-transform: uppercase;
                        font-size: 19px;
                        font-weight: 700;
                        color: var(--mainColor2);
                    }
                }
            }
        }
        .cash_game_menu {
            width: 1000px;
            position: absolute;
            top: 620px;
            .back_lobby {
                display: flex;
                align-items: center;
                position: relative;
                border-radius: 4px;
                z-index: 3;
                top: 100px;
                left: 0px;
                width: 106px;
                height: 44px;
                background: linear-gradient(to right, #6a0000 0%, #ab0023 100%);
                cursor: pointer;

                span {
                    text-transform: uppercase;
                    color: var(--mainColor2);
                    font-size: 15px;
                    font-weight: 500;
                }
            }
            .cash_games {
                .cash_game_menu_block {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 620px;
                    position: absolute;
                    width: 100%;
                    top: 0;
                }
            }
        }
    }
}

.move_to_top {
    margin-top: -620px;
    transition: all 0.4s ease-in-out;
}
.move_to_bottom {
    margin-top: 0px;
    transition: all 0.4s ease-in-out;
}
.to_animate {
    top: 160px;
}
@keyframes backLobbyAnimate {
    0% {
        width: 106px;
    }
    100% {
        width: 120px;
    }
}
