.score_info {
    position: absolute;
    display: flex;
    top: 25px;
    right: 38px;
    width: 172px;
    height: 62px;
    .us_score_text,
    .them_text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        color: #0d4619;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 14px;
        height: 25px;
    }
    .us_score,
    .them_score {
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        height: 35px;
        font-size: 14px;
        font-family: 'Roboto';
        font-weight: 700;
        color: var(--cColor);
    }
    .us_score_block {
        display: flex;
        flex-direction: column;
        width: 86px;
        .us_score_text {
            background: var(--mainColor4);
            border-top-left-radius: 4px;
        }
        .us_score {
            border-bottom-left-radius: 4px;
            border: 2px solid var(--mainColor4);
        }
    }
    .them_score_block {
        display: flex;
        flex-direction: column;
        width: 86px;
        .them_text {
            background: var(--mainColor3);
            border-top-right-radius: 4px;
        }
        .them_score {
            border-bottom-right-radius: 4px;
            border: 2px solid var(--mainColor3);
        }
    }
}
