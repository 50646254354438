.tournament-info {
    display: flex;
    .tour-cup {
        background: linear-gradient(to right, #504435 0%, #b59a74 100%);
        box-shadow: 12px 0px 12px rgba(0, 0, 0, 0.16);
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 414px;
        height: 620px;
        z-index: 1;
        .tour-info-img {
            width: 350px;
            height: 500px;
            margin: 0 auto;
            img {
                width: 100%;
            }
        }
        span {
            font-size: 40px;
            color: var(--mainColor2);
            font-family: 'Roboto-slab';
            text-transform: uppercase;
            text-align: center;
            padding-top: 20px;
            letter-spacing: 1px;
        }
    }
    .tour-info-content {
        position: absolute;
        .tournament-bg {
            width: 1000px;
            .tournament-bg-pattern {
                &::before {
                    background-position: -4px -8px;
                    background-size: 100% 100%;
                }
                .down-wrapper{
                    position: absolute;
                    width: 525px;
                    height: 65px;
                    left: 45%;
                    top: 83%;
                    .down-block {
                        position: relative;

                            .tour-info-more {
                                position: absolute;
                                right: 150px;
                                cursor: pointer;
                                height: 46px;
                                .button-tree {
                                    button {
                                        background: #84745d;
                                        span {
                                            font-size: 15px;
                                        }
                                    }
                                    .button-tree-under {
                                        background: #3b281f;
                                        bottom: -7px;
                                    }
                                }
                            }
                            .tour-info-join {
                                cursor: pointer;
                                position: absolute;
                                right: 0;
                                height: 46px;
                                .button-tree {
                                    button {
                                        background: linear-gradient(
                                            to right,
                                            #3d9198 0%,
                                            #1f5252 100%
                                        );
                                        span {
                                            font-size: 15px;
                                        }
                                    }
                                    .button-tree-under {
                                        background: #323a32;
                                        bottom: -6px;
                                    }
                                }
                            }
                        
                    }
                }
            }
        }
    }
}
