.round_score {
    width: 554px;
    height: 442px;
    border: 1px solid var(--mainColor7);
    background: rgba(0, 66, 4, 1);
    position: absolute;
    left: calc(50% - 272px);
    border-radius: 11px;
    top: calc(50% - 221px);
    z-index: 1;
    .round_score_contract {
        display: flex;
        justify-content: center;
        margin-top: -40px;
        margin-bottom: -10px;
    }
    .round_score_content {
        width: 524px;
        height: 347px;
        display: flex;
        background: rgba(8, 92, 17, 1);
        margin: 0 15px;
        margin-top: 40px;
        border-radius: 11px;
        .round_score_table_content {
            width: 100%;
            margin-top: 5px;
            .round-img {
                position: relative;
                top: 35px;
                left: 12px;
                height: 31px;
                width: 192px;
                /* padding: 0px 0; */
                background: #02430d;
                border-radius: 5px;
                text-align: center;
                margin-bottom: 2px;
                color: var(--mainColor2);

                font-family: 'Roboto';
                font-weight: 700;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 28px;
                    margin-left: 5px;
                }
                span {
                    position: relative;
                    margin-left: 15px;
                    font-size: 20px;
                }
            }
            .round_score_line {
                &:first-child {
                    .round_score_item {
                        color: #edb346;
                        font-size: 16px;
                        font-family: 'Roboto';
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }
                &:last-child {
                    margin-top: 10px;
                    .round_score_text {
                        background: var(--mainColor23);
                        border-radius: 5px;
                    }
                    .round_score_item {
                        font-size: 18px;
                        font-family: 'Roboto';
                        font-weight: 700;
                        background: var(--mainColor23);
                    }
                }
            }
        }
        .round_score_content_us {
            padding-top: 15px;
            margin-left: 5px;
        }
        .round_score_content_them {
            padding-top: 15px;
            margin-left: 3px;
        }
        .round_score_content_total {
            display: flex;
        }
    }
    .start_new_game {
        display: block;
        text-align: center;
        color: rgba(215, 175, 134, 1);
        font-size: 20px;
        font-family: 'Dejavu-book';
        padding-top: 16px;
    }
    .ribbon {
        position: absolute;
        width: 428px;
        top: -46px;
        left: calc(50% - 216px);
        img {
            width: 100%;
        }
        span {
            display: flex;
            justify-content: center;
            position: relative;
            top: -60px;
            font-size: 22px;
            font-family: 'DejaVu-Sans-Condensed';
            color: var(--cColor);
        }
    }
}
