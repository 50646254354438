.chat-close {
    position: absolute;
    width: 790px;
    height: 585px;
    z-index: 888;
    top: 0;
}
.user-chat {
    position: absolute;
    top: -55px;
    z-index: 1;
    background-image: url('../../../assets/images/icons/user-chat.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 150px;
    height: 65px;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        height: 60px;
    }
    .user-chat-id {
        span {
            font-size: 12px;
        }
    }
}

.user-chat-3 {
    top: 90px;
    transform: rotate(180deg);
    span {
        transform: rotate(180deg);
    }
}
