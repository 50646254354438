.discount {
    position: absolute;
    top: -6px;
    .discount_count {
        display: flex;
        position: absolute;
        top: 38px;
        justify-content: center;
        align-items: center;
        left: 33px;
        img {
            z-index: 9;
            position: relative;
        }
        .discount-timer {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            .discount-time-block {
                padding-bottom: 3px;
                color: var(--mainColor2);
                font-size: 14px;
                font-weight: 500;
                .timer {
                    display: flex;
                }
            }
        }
    }
}
