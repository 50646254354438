.store_popup {
    height: 540px;
    position: absolute;
    bottom: 0;
    display: flex;
    width: 1000px;
    margin: 0 auto;
    .back-lobby-store {
        height: 124px;
        position: relative;
        bottom: 50px;
    }
    .content_store {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(67, 0, 0, 0.68);
        box-shadow: 0px -6px 12px rgba(0, 0, 0, 0.16);
        width: 1000px;
        height: 460px;
        position: absolute;
        bottom: 0;
    }
}
