.announcement {
    display: flex;
    position: absolute;
    z-index: 999;
    margin: auto;
    width: 200px;
    height: 100px;

    .announce-card {
        width: 80px;
        height: 110px;
    }
    &.announcement-2 {
        left: 160px;
        top: 50%;
        transform: translateY(-10%);
        justify-content: center;
        animation: bounce-vertical 4s;
        width: 200px;
        height: 100px;
    }
    &.announcement-3 {
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        justify-content: center;
        animation: bounce-horizontal 4s;
        display: flex;
        width: 200px;
        height: 100px;
    }
    &.announcement-4 {
        right: 0px;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        animation: bounce-vertical 4s;
        width: 200px;
        height: 100px;
    }

    &.horizontal {
        .announce-group {
            align-items: center;
            position: relative;
            margin-left: 30px;

            &:first-child {
                margin-left: 0;
            }

            .announce-card {
                position: absolute;
                z-index: 999;
            }
        }
    }

    &.vertical {
        flex-direction: column;
        top: -80px;
        bottom: 0;

        .announce-group {
            flex-direction: column;
            display: flex;
            position: relative;
            //align-items: center;
            //flex-direction: column;

            .announce-card {
                position: absolute;
            }
        }
    }
}

@keyframes bounce-vertical {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    30% {
        transform: scale(1.1);
    }
    40% {
        transform: scale(1);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes bounce-horizontal {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    30% {
        transform: scale(1.1) translateX(-50%);
    }
    40% {
        transform: scale(1) translateX(-50%);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
