.dashboard-tab {
    display: flex;
    padding-top: 10px;
    padding-left: 20px;
    .leaderboard-tab {
        background: rgba(0, 0, 0, 0.6);
        width: 120px;
        height: 48px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        cursor: pointer;
        .tab-text {
            font-weight: 700;
            text-transform: capitalize;
            color: #888;
            display: flex;
            font-size: 16px;
            justify-content: center;
            align-items: center;
            height: 100%;
            &.active-text {
                color: var(--mainColor2);
            }
        }
    }
    .active {
        background: rgba(0, 0, 0, 0.2);
    }
    .dashboard-tab-img {
        position: absolute;
        right: 12px;
    }
}
