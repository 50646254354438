.last_cards {
    width: 25px;
    height: 33px;
    background: var(--cColor);
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 10px;
        height: 10px;
    }
}
