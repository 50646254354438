.round_score_line {
    .round_score_block {
        display: flex;
        justify-content: center;
        .round_score_text_contra {
            width: 192px;
            padding: 8px 0;
            background: rgba(2, 67, 13, 1);
            border-radius: 5px;
            text-align: center;
            margin-bottom: 2px;
            color: var(--mainColor2);
            font-size: 16px;
            font-family: 'Roboto';
            font-weight: 700;
            text-transform: uppercase;
        }
        .order_contra {
            background: #cb2429;
            width: 300px;
            margin-left: 3px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--mainColor2);
            font-size: 21px;
            font-family: 'Roboto';
            font-weight: 700;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 5px;
            }
            .order_contra_high {
                padding-top: 5px;
            }
        }
    }
}
