.tour-user-panel {
    height: 56px;
    background: linear-gradient(to right, #3b2d1b 0%, #b59a74 100%);
    box-shadow: 0px 6px 12px #00000029;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tour-user-panel-left {
        display: flex;
        justify-content: center;
        align-items: center;
        .tour-img-panel {
            position: relative;
            top: 17px;
            left: 10px;
            cursor: pointer;
            width: 65px;
            img {
                width: 100%;
            }
        }
        span {
            font-family: 'Roboto-Slab';
            font-size: 40px;
            color: var(--mainColor2);
            font-weight: 700;
            text-transform: capitalize;
            padding-left: 30px;
            letter-spacing: 1px;
        }
    }
    .tour-user-panel-right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        .tour-panel-timer {
            background: rgba(56, 41, 25, 0.74);
            display: flex;
            width: 325px;
            height: 40px;
            border-radius: 20px;
            align-items: center;
            margin-right: 20px;
            .tour-panel-timer-img {
                width: 40px;
                img {
                    width: 100%;
                }
            }
            .tour-user-panel-timer {
                width: 100%;

                .timer {
                    display: flex;
                    position: relative;
                    left: 25px;
                    p {
                        padding-left: 0px;
                        padding-right: 10px;
                    }
                    span {
                        font-size: 34px;
                        padding-left: 0px;
                        padding-right: 10px;
                        color: var(--mainColor2);
                        font-family: 'Roboto-Slab';
                    }
                }
            }
            img {
                margin-left: 3px;
            }
            p {
                font-family: 'Roboto-Slab';
                font-size: 34px;
                color: var(--mainColor2);
                font-weight: 700;
                padding-left: 40px;
            }
        }
        .tour-home-icon {
            border-left: 1px solid rgba(255, 255, 255, 0.24);
            height: 40px;
            width: 45px;

            position: relative;
            cursor: pointer;
            img {
                width: 100%;
                position: absolute;
                top: 0;
                left: 15px;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
}
