.unlock-three{
    position: relative;
    display: flex;
    align-items: center;
    button{
        display: flex;
        align-items: center;    
        background: #84745D;
        height: 64px;
        width: 525px;
        border-radius: 5px;
        z-index: 3;
        .unlock-img{
            width: 24px;
            margin-left: 12px;
            img{
                width: 100%;
            }
        }
        .unlock-text{
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            span{
                font-size: 15px;
                text-transform: uppercase;
                font-family: "Mardoto";
                font-weight: bold;  
                color: var(--mainColor2);
            }
            :last-of-type{
                text-transform: capitalize;
                font-size: 13px;
                font-weight: lighter;
            }
        }
        .unlock-btn{
            margin-left: 40px;
            padding-left: 40px;
            color: var(--mainColor2);
            font-family: "Mardoto";
            font-size: 15px;
            font-weight: bold;
            text-transform: uppercase;
            border-left: 1px solid var(--mainColor2);
            cursor: pointer;
        }
    }
    &:active {
        margin-top: 4px;
        position: absolute;
    }
    .button-tree-under {
        height: 66px;
        background: rgba(23, 19, 0, 0.55);
        width: 525px;
        position: absolute;
        z-index: 1;
        bottom: -8px;
        border-radius: 5px;
        &:active {
            bottom: -10px;
            background: none;
        }
    }
}