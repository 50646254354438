.dashboard-games {
    position: relative;
    top: 100px;
    width: 100%;
    height: 520px;
    .games-count {
        display: flex;
        justify-content: space-between;
        margin: 0 25px;
        span {
            font-size: 32px;
            font-family: 'Roboto-slab';
            color: var(--mainColor2);
            letter-spacing: 1px;
            text-transform: capitalize;
        }
        .games-count-right {
            display: flex;
            flex-direction: column;
            position: relative;
            right: 10px;
            span {
                font-size: 21px;
                font-weight: 700;
                text-align: right;
                letter-spacing: 0px;
                padding-bottom: 5px;
            }
            .level-bar {
                min-width: 114px;
                height: 8px;
                background: #2c251c;
                border-radius: 4px;
                position: relative;
                border: none;
                .level-full {
                    left: 1px;
                    height: 6px;
                    top: 1px;
                }
                .games-count-progress-animation {
                    width: 80px;
                    height: 6px;
                    position: absolute;
                    top: 1px;
                    background: linear-gradient(#ffffff 0%, #a2a2a2 100%);
                    border-radius: 4px;
                    left: 1px;
                }
            }
        }
    }
    .tour-game-count-block {
        position: relative;
        top: 23px;
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        height: 450px;
    }
}
