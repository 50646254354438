.pie_chart {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-sizing: border-box;
    opacity: 0;

    .standard_label {
        position: absolute;
        margin: auto;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
    }

    &.animate {
        animation: scale, fadeIn;
        animation-duration: 1s;
        animation-fill-mode: forwards;

        svg {
            animation: rotate, fadeIn;
            animation-duration: 1s;
            animation-fill-mode: forwards;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes rotate {
    from {
        transform: rotate(-180deg) scale(0.3);
    }
    to {
        transform: rotate(-90deg) scale(1);
    }
}

@keyframes scale {
    from {
        transform: scale(0.3);
    }
    to {
        transform: scale(1);
    }
}
