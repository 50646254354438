.close_button {
    position: absolute;
    right: 12px;
    top: 12px;
    transition: transform 0.3s;

    cursor: pointer;
    &:hover {
        transform: scale(1.3);
    }
}
