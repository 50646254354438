.user-contra {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    background: red;
    top: -40px;
    left: 45px;
    span {
        position: absolute;
        top: 3px;
        left: 8px;
        right: 0;
        bottom: 0;
        margin: auto;
        color: var(--mainColor2);
        font-size: 18px;
        font-weight: 700;
    }
}
.user-contra-2 {
    top: -40px;
    left: 45px;
}
.user-contra-3 {
    left: 175px;
    top: 100px;
}
.user-contra-4 {
    left: 44px;
}

.user-contra-1 {
    left: 47px;
    top: -35px;
}
