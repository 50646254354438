.user-menu {
    position: absolute;
    display: flex;
    justify-content: space-around;
    bottom: 0px;
    left: calc(50% - 180px);
    width: 360px;
    height: 36px;
    background: var(--mainColor6);
    border: 1px solid var(--mainColor6);
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
    &-icon {
        width: 32px;
        height: 32px;
        background: rgba(0, 0, 0, 0.2);
        position: relative;
        border-radius: 50%;
        cursor: pointer;
        top: 1px;
        .menu-under-icon {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}

.icon-true {
    opacity: 0.5;
    cursor: auto;
}
